.modalForm{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 101;
    max-height: 100vh;
    width: 100vw;
    max-width: 1140px;
    background: white;
    padding: 50px 40px;
    p{
        white-space: break-spaces;
    }
    > .button-close{
        position: absolute;
        right: 10px;
        top: 10px;
        width: 35px;
        height: 35px;
        font-size: 18px;
        line-height: 2;
    }

}
.Backdrop {
    top: 0;
    z-index: 100;
}