.formField{
    
    label,input,textarea{
        width: 100%;
    }
    input,textarea{
        border: 0px;
        margin-bottom: 30px;
        border-radius: 16px;
        background: #E4E4E4 0% 0% no-repeat padding-box;
        padding: 18px 15px;
        border: 2px solid transparent; 
        @include font-size(14);
        &:hover,&:focus{
            outline: unset;
        }
        &.error{
            border-color: $red;
        }
        -webkit-box-shadow: inset -2px -2px 6px 4px #898989; 
        box-shadow: inset -2px -2px 6px 3px #898989;

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus
        {
            -webkit-text-fill-color: $black !important;
            -webkit-box-shadow: inset -2px -2px 6px 3px #898989 !important;
            -webkit-transition: background-color 5000s ease-in-out 0s !important;
            transition: background-color 5000s ease-in-out 0s !important;
        }
    }
    .errorMsg{
        margin-top: -28px;
        margin-left: 18px;
        @include font-size(12);
        font-weight: 600;
        @media (min-width: 768px){
            position: absolute;
        }
    }
    textarea{
        resize: none;
    }
}



#contacts-banner{
    label{
        color: white;
    }
    @media ( min-width: 992px ) and (max-width: 1199px) {
        input[name='name'],input[name='email']{
            margin-bottom: 32.3px;
        }
    }
}

