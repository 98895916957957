$black: #000;
$silver: #E4E4E4;
$light-silver: #F1F2F5;
$dark-silver: #BFBFBF;
$grey: #4A4949;
$green: #007B7B;
$dark-green: #004848;
$light-green: #59BDB9;
$blue: #8BD5D1;
$light-blue: #e3f1f3;
$red: #B22A1D;
$orange: #DD5100;
$light-orange: #FF8618;
$yellow: #FFB128;
$light-yellow: #FFCA31;