.tech-visa-bg{
    height: 600px;
    filter: drop-shadow( 0px 20px 10px #00000029);
    position: relative;
    &::before{
        content: " ";
        clip-path: polygon(100% 15%, 100% 90%, 0 100%, 0 0);
        position: absolute;
        width: 100%;  
        height: 90%;  
        background: $black;
        @media(min-width:768px){
            clip-path: polygon(100% 15%, 85% 100%, 0 75%, 0 0);
            height: 100%;  
        }
    }
    z-index: 100;
    .tech-visa-content{
        height: 100%;
        .arrow{
            clip-path: polygon(0 0, 80% 11%, 100% 45.5%, 80% 100%, 0% 89%);
            height: 250px;
            width: 300px;
            transform: translateY(-5%);
            @media(min-width:768px){
                height: 350px;
                width: 500px;
                transform: translateY(-5%);

            }
            .title-triangle{
                left: 15%;
                position: relative;
            }
            p, h2{
                max-width: 450px;
                margin-left: auto;
                margin-right: auto;
            }
            .p-4{
                @media ( max-width: 575px ) {
                    
                    transform: translateY(20px);
                }
            }
        }
    }
    h2.white{
        @media (min-width: 1200px) {
            @include font-size(32)
        }
    }
    p{
        @media (min-width: 1200px) {
            @include font-size(18)
        }
    }
    button{
        box-shadow: none;
        border: 0.2px solid #FFFFFF;
    }
}