.IconTitle{
    .title-triangle{
        margin-left: 70px;
    }
    .iconTitleItem{
        display: flex;
        flex-direction: row;
        align-items: center;
        h3{
            margin: 0;
        }
        .icon{
            box-shadow: 5px 5px 11px #6565654A, -5px -5px 11px #FFFFFF;
        }
    }
}