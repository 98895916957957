.header {
    padding: 38px 0;
    position: absolute;
    width: 100%;
    z-index: 100;

    img {
        transition: filter 0.3s ease-in-out;
        // &.filter-invert{
        //     filter: invert(1);
        // }
    }

    .small {
        transform: scale(0.7);
        margin: 0;

        &:hover,
        &:focus {
            transform: scale(0.8);
        }
    }

    nav>button {
        padding: 5px 12px !important;
        position: fixed;

        &:hover {
            transform: scale(1);
        }

        @media not all and (min-resolution:.001dpcm) {
            @supports (-webkit-appearance:none) {
                @media (max-width: 767px) {
                    transform: translateX(-5px);

                }
            }
        }

        &:first-child {
            transform: translateX(-60px);

            @media not all and (min-resolution:.001dpcm) {
                @supports (-webkit-appearance:none) {
                    transform: translateX(-80px);
                }
            }

            &:hover {
                transform: translateX(-60px) scale(1);

                @media not all and (min-resolution:.001dpcm) {
                    @supports (-webkit-appearance:none) {
                        transform: translateX(-80px) scale(1);
                    }
                }
            }
        }

        @media (max-width: 1199px) {
            width: 40px;
            height: 40px;
        }
    }

    .locale {
        position: fixed;
        height: 0;
        opacity: 0;
        top: 0;
        background: #F7F7F8A3 0% 0% no-repeat padding-box;
        overflow: hidden;
        border-radius: 33px;
        border: 1px solid #FFFFFF;
        transition: all 0.3s linear;
        padding: 5px 0;
        z-index: 101;

        .button {
            padding: 5px 12px !important;
        }

        /*
        transform: translate(0, 30px);
        @media (min-width:576px){
            transform: translate(2px, 40px);
        }
        @media (min-width:992px){
            transform: translate(2px, 45px);
        }
        @media (min-width:1200px){
            transform: translate(2px, 45px);
        }*/
        @media not all and (min-resolution:.001dpcm) {
            @supports (-webkit-appearance:none) {
                transform: translate(21px, 0);

                @media (min-width: 576px) {
                    transform: translate(20px, 0);
                }

                @media (min-width: 992px) {
                    transform: translate(20px, 0);
                }

                @media (min-width: 1200px) {
                    transform: translate(21px, 0);
                }
            }
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                width: 100%;

                button {
                    background: transparent;
                    border: 0;
                    font-weight: 700;
                    width: 100%;
                    padding: 0;
                    text-align: center;
                    margin: 7px 0;

                    &.active,
                    &:focus,
                    &:hover {
                        color: $orange;
                    }

                    @media(min-width:768px) {
                        margin: 5px 0;
                    }
                }
            }
        }

        &.show {
            height: 150px;

            @media(min-width: 1200px) {
                height: 165px;
            }

            opacity: 1;

            >button {
                transform: scale(1.05);
                color: $orange;
                background: transparent linear-gradient(-109deg, #FFFFFF 0%, #E7E8EB 100%) 0% 0% no-repeat padding-box;
                border-color: #FFFFFF;
            }

            .iconG,
            .iconM {
                fill: $orange;
            }
        }
    }

    button {

        &:hover,
        &:focus {
            &+h3 {
                color: $orange;
            }
        }
    }

    h3 {

        &:hover,
        &:focus {
            color: $orange;
        }
    }

    .accordion {
        button {
            border: 0;
        }

        .AccordionItemUI::after {
            content: unset !important;
        }
    }

    .iconG {
        line-height: 1.6;
        height: 14px;
        margin-top: -2px;

        @media(min-width: 768px) {
            height: 16px;
        }

        @media(min-width: 1200px) {
            height: 20px;
        }
    }

    .iconM {
        line-height: 1.6;
        height: 10px;

        @media(min-width: 768px) {
            height: 12px;
        }

        @media(min-width: 1200px) {
            height: 15px;
        }
    }

    .menu {
        position: fixed;
        margin-right: 10px;
        padding: 15px;
        background: #E4E4E4;
        border: 0.2px solid transparent;
        box-shadow: 5px 5px 11px rgba(101, 101, 101, 0.2901960784);
        border-radius: 50px;

        @media (max-width:767px) {
            padding: 10px;
        }

        .button {
            height: 42px;
            width: 42px;
            position: relative;
            z-index: 102;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0px;
            background: transparent linear-gradient(109deg, #FFFFFF 0%, #E7E8EB 100%) 0% 0% no-repeat padding-box;
            border: 0.2px solid transparent;
            box-shadow: 5px 5px 11px rgba(101, 101, 101, 0.2901960784);
            border-radius: 50px;
            margin: 0 15px 0 20px;
            transition: all 0.3s ease-out;

            &.globe {
                height: 42px;
                width: 41px;
            }

            &.active {
                -webkit-transform: scale(1.05);
                -ms-transform: scale(1.05);
                transform: scale(1.05);
                color: #dd5100;
                background: rgba(0, 0, 0, 0) linear-gradient(-109deg, #fff, #e7e8eb) 0 0 no-repeat padding-box;
                border-color: #fff;
            }

            img {
                position: absolute;
            }
        }

        .socials {
            .button {
                margin: 0 5px;

                @media (max-width:767px) {
                    margin: 0;
                }
            }

            img {
                height: 30px;
                width: 30px;
            }
        }

        .locale-container {
            position: relative;

            .locale-menu {
                position: absolute;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                width: calc(100% + 14px);
                height: 0;
                opacity: 0;
                top: -7px;
                left: -7px;
                background: #F7F7F8A3 0% 0% no-repeat padding-box;
                overflow: hidden;
                border-radius: 33px;
                border: 1px solid #FFFFFF;
                transition: all 0.3s linear;
                padding: 5px 0;
                z-index: 101;

                @media (max-width:767px) {
                    width: calc(100% + 12px);
                    top: -6px;
                    left: -6px;
                }

                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    transition: all 0.3s ease-in;
                    transition: all 0.1s ease-out;
                    opacity: 0;

                    li {
                        width: 100%;

                        button {
                            background: transparent;
                            border: 0;
                            font-weight: 700;
                            width: 100%;
                            padding: 0;
                            text-align: center;
                            margin: 7px 0;

                            &.active,
                            &:focus,
                            &:hover {
                                color: $orange;
                            }

                            @media(min-width:768px) {
                                margin: 5px 0;
                            }
                        }
                    }
                }

                &.show {
                    height: 160px;

                    @media(min-width: 1200px) {
                        height: 165px;
                    }

                    ul {
                        transition-delay: 0.3s;
                        opacity: 1;
                    }

                    opacity: 1;

                    >button {
                        transform: scale(1.05);
                        color: $orange;
                        background: transparent linear-gradient(-109deg, #FFFFFF 0%, #E7E8EB 100%) 0% 0% no-repeat padding-box;
                        border-color: #FFFFFF;
                    }

                    .iconG,
                    .iconM {
                        fill: $orange;
                    }
                }
            }
        }
    }

    .socials {
        position: relative;

        .button {
            &.like-icon {
                img {
                    color: $black;
                    width: 20px;
                    height: 20px;
                    transition: all 0.2s;
                }

                &.active {
                    img {
                        width: 22px;
                        height: 22px;
                    }
                }
            }
        }

        .socials-menu {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            width: calc(100% + 14px);
            height: 0;
            opacity: 0;
            top: -7px;
            left: -7px;
            background: #F7F7F8A3 0% 0% no-repeat padding-box;
            overflow: hidden;
            border-radius: 33px;
            border: 1px solid #FFFFFF;
            transition: all 0.3s linear;
            padding: 5px 0;
            z-index: 101;

            @media (max-width:767px) {
                width: calc(100% + 12px);
                top: -6px;
                left: -6px;
            }

            .button {
                transition: all 0.3s ease-in;
                transition: all 0.1s ease-out;
                opacity: 0;
            }

            &.show {
                height: 250px;
                opacity: 1;
                display: flex;
                flex-direction: column;
                align-items: center;

                .button {
                    margin: 3px 0;
                    opacity: 1;

                    &:nth-child(1) {
                        transition-delay: 0.3s;
                    }

                    &:nth-child(2) {
                        transition-delay: 0.25s;
                    }

                    &:nth-child(3) {
                        transition-delay: 0.2s;
                    }

                    &:nth-child(4) {
                        transition-delay: 0.15s;
                    }
                }
            }
        }
    }
}