.blogBanner {
    height: auto;
    max-height: unset;
    min-height: unset !important;
    overflow: initial;

    .container.d-none {
        display: block !important;
    }

    .white-mountain {
        max-height: 100vh;
    }

    .light-blue-mountain-shadow {
        width: 100vw;
        height: 100%;
        position: absolute;
        background-color: $light-blue;
        top: 170px;
        filter: drop-shadow(0px -10px 30px #00484841);
        left: 0;
        z-index: 0;

        //top: -100px;
        &:before {
            width: 100vw;
            height: 100px;
            clip-path: polygon(30% 0%, 0% 60%, 100% 60%);
            content: " ";
            position: absolute;
            background-color: $light-blue;
            top: -60px;

            @media(min-width: 768px) {
                clip-path: polygon(30% 20%, 0% 100%, 100% 100%);
                height: 100px;
                top: -100px;
            }
        }

        @media(min-width: 768px) {
            top: 220px;
        }

        // &:after{
        //     content: " ";
        //     width: 100vw;
        //     height: 100%;
        //     position: absolute;
        //     background-color: $light-blue;
        //     bottom: -100px;
        // }    

    }

    .bottom-mountain {
        filter: unset;
        bottom: unset;
        top: 60vh;

        &::before {
            background-color: $light-blue !important;
            clip-path: unset;
        }

        &.shadow {
            filter: none;
        }
    }

    .newUI {
        white-space: break-spaces;
        width: 100%;
        margin-top: 20%;

        @media(min-width:1200px) {
            margin-top: 10%;
        }

        a {
            color: $green;
            text-decoration: underline;

            &:hover {
                color: $green;
                opacity: .9;
            }
        }

        .news-table {
            border: 1px solid $black;
            border-collapse: collapse;

            tr,
            td,
            th {
                border: 1px solid $black;
                padding: 10px;
                text-align: center;
            }
        }

        ul {
            padding: 0;
            list-style: none;
        }

        .full-date {
            color: $green;
            font-size: 13px;
        }

        .popularNews {
            padding-top: 30px;
            z-index: 0;
            position: relative;

            @media(max-width: 991px) {
                display: none;
            }

            @media(min-width: 1200px) {
                width: 110%;
            }

            &::after {
                content: " ";
                width: 133.33333%;
                left: 0;
                top: -50px;
                z-index: 1;
                height: 100%;
                position: absolute;
                clip-path: polygon(100% 18%, 100% 100%, 0 100%, 0 14%, 25% 0%);
                background-color: $green;

                @media (min-width: 1200px) {
                    width: 120%;
                }

                @media (min-width: 2000px) {
                    width: 50vw;
                    clip-path: polygon(100% 18%, 100% 100%, 0 100%, 0 14%, 15% 0%);

                }
            }

            &::before {
                content: " ";
                width: 138.33333%;
                left: -5%;
                top: -50px;
                height: 105%;
                position: absolute;
                clip-path: polygon(100% 12%, 100% 100%, 0 91%, 13% 15%);
                background-color: $black;

                @media (min-width: 1200px) {
                    width: 110%;
                }

                @media (min-width: 2000px) {
                    width: 50vw;
                    clip-path: polygon(100% 10%, 100% 100%, 0 91%, 10% 6%);

                }
            }

            h2 {
                margin-left: 35px;
            }

            h2,
            .title-triangle {
                position: relative;
                z-index: 2;
            }

            .title-triangle {
                margin-left: 65px;
            }

            ul {
                position: relative;
                z-index: 2;
                list-style: none;
                margin: 0;
                padding-bottom: 70px;
                padding-top: 20px;
                padding-left: 25px;

                li {
                    padding: 10px 0;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .button {
                        box-shadow: -5px -5px 11px #FFFFFF84, 0px 2px 2px #FFFFFF84;
                    }

                    p {
                        padding: 0;
                        margin: 0;
                        text-transform: uppercase;
                        color: white;
                        font-weight: 600;
                        @include font-size(14);
                    }
                }
            }
        }

        .max-h-100 {
            max-height: 100px;
        }

        .IconsNew {
            p {
                color: #007b7b;
                font-weight: 700;
            }

            .iconNew .button {
                width: 45px;
                height: 45px;

                svg {
                    width: 1.5em;
                    height: 1.5em;
                    padding-top: 5px;
                }
            }
        }

        // .wrap-img{
        //     position: relative;
        //     h2{
        //         position: absolute;
        //         top: 0;
        //         left: 60px;
        //         text-transform: uppercase;
        //         font-size: 16px;
        //         font-weight: 700;
        //         margin-top: 20px;
        //         max-width: 80%;
        //     }
        //     &::before{
        //         content: " ";
        //         width: 100%;
        //         min-height: 70px;
        //         height: auto;
        //         background-color: $white;
        //         position: absolute;
        //         top: 0;
        //         left: 0;
        //         clip-path: polygon(0 0, 100% 0, 85% 100%, 0% 90%);
        //     }
        //     &:after{
        //         content: "";
        //         background: url("../../assets/images/logos/icon.png") no-repeat;
        //         background-size: 35px 35px;
        //         width: 50px;
        //         height: 50px;
        //         position: absolute;
        //         top:12.5px;
        //         left: 10px;
        //     }
        // }

        code {
            color: $orange;
            text-align: left;
            display: inline-block;
        }

        .separator {
            position: relative;
            margin: 10px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: unset;

            &::before {
                display: block;
                position: relative;
                content: "";
                width: 50%;
                height: 2pt;
                background-color: $green;
            }
        }

        p {
            span {
                transform: unset;
                text-align: left;
            }
        }
    }

    .animation.col-12 {
        transform: translateY(200vh);
    }

    .animation.start {
        span {
            &.bottom-mountain {
                max-height: unset;
            }
        }

        .animation:nth-child(3) {
            transition: transform 1s 1s;
        }

        &.col-12 {
            transform: translateY(0);
        }
    }

    .arrow-container {
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
        /* background: transparent linear-gradient(109deg, #FFFFFF 0%, #E7E8EB 100%) 0% 0% no-repeat padding-box;
        border: 0.2px solid transparent;
        box-shadow: 5px 5px 11px rgba(101, 101, 101, 0.2901960784);
        border-radius: 50px; */
        margin-right: 5px;

        .arrow {
            width: 18px;
            height: 18px;

            svg {
                width: 100%;
                height: 100%;
            }
        }
    }
}

#blog-banner {

    .slick-prev,
    .slick-next {
        top: 25%;
    }

    margin-bottom: 30px;

    .title-triangle {
        margin-left: 60px;
        margin-top: 8vh;

        @media(min-width:768px) {
            margin-top: 15vh;
        }
    }

    .blogUI {
        padding-bottom: 40px;

        &>.my-5 {
            margin-top: 0 !important;
            padding-top: 0 !important;
        }

        @media (max-width: 767px) {
            margin-right: -15px;
        }
    }
}

#blog-section2 {
    position: relative;
    filter: drop-shadow(0px -10px 20px rgba(0, 0, 0, 0.3));

    &::before {
        content: " ";
        width: 100%;
        height: 50px;
        position: absolute;
        clip-path: polygon(15% 0, 100% 100%, 100% 100%, 0% 100%);
        background: $light-silver;
        top: -49.5px;
        left: 0;
    }

    &::after {
        content: " ";
        position: absolute;
        bottom: calc(-100px - 3rem);
        width: 100%;
        height: calc(100px + 3rem);
        background: $light-silver;
    }

    .title-triangle {
        margin-left: 60px;
    }
}