.box-info{
    background-color: $white;
    box-shadow: -5px -5px 15px #ffffffcb, inset -5px -5px 15px #0000001A;
    border-radius: 5px;
    padding: 20px 25px;
    @media(min-width:768px){
        padding: 50px 60px;

    }
    h2{
        text-transform: uppercase;
        font-weight: 600;
    }
    p{
        line-height: 1.7;
        font-weight: 500;
        margin-bottom: 0;
    }
}