.button {
    color: $black;
    background: transparent linear-gradient(109deg, #FFFFFF 0%, #E7E8EB 100%) 0% 0% no-repeat padding-box;
    border: 0.2px solid transparent;
    box-shadow: 5px 5px 11px #6565654A;
    border-radius: 50px;
    margin: 0 10px;
    transition: all 0.3s ease-out;
    flex-shrink: 0;

    &.p-l {
        padding: 8px 16px;

        @media(min-width: 768px) {
            padding: 10px 25px;
        }

        @media(min-width: 1200px) {
            padding: 12px 25px;
        }
    }

    &.p-s {
        padding: 5px 10px;

        @media (min-width: 992px) {
            padding: 5px 15px;
        }
    }

    &:hover,
    &:focus,
    &.active {
        border-color: #FFFFFF;
        color: $orange;
        background: transparent linear-gradient(-109deg, #FFFFFF 0%, #E7E8EB 100%) 0% 0% no-repeat padding-box;
        transform: scale(1.05);

        .iconG,
        .iconM {
            fill: $orange;
        }
    }

    &.wh-35 {
        width: 35px;
        height: 35px;
    }

    &.loading {
        position: relative;

        &::after {
            content: " ";
            border: 8px solid $light-yellow;
            position: absolute;
            border-top: 8px solid $black;
            border-radius: 50%;
            width: 40px;
            right: -60px;
            top: 0;
            height: 40px;
            animation: load2 2s infinite ease;

            @media (max-width: 1299px) {
                left: -60px;
                right: initial;
            }
        }
    }
}