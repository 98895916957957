.buttonOvalImage{
    overflow: hidden;
    position: relative;
    .OvalImage{
        @media (min-width: 992px) {
            padding-top: 5%;
            padding-bottom: 14%;
        }
        .backgroundImg{
            display: none;
            @media (min-width: 992px) {
                display: initial;
                position: absolute;
                width: 97%;
                max-width: 960px;
                transform: translate(-2%, 4%) scale(1.2);
            }
            @media (min-width: 1200px) {
                transform: translate(-4%, -1%) scale(1.1);
                width: 96%;
                max-width: 1140px;
            }
            @media (min-width: 1300px) {
                transform: translate(-7%, -4%);
            }
            @media (min-width: 1400px) {
                transform: translate(-7%, -5%);
                width: 94%;
                max-width: 1140px;
            }
        }
    }
    img{
        @media(min-width:1200px){
            margin-top:60px;
            margin-left:-155px;
        }
    }
}

//exceptions

#attendenceManagement-section2{
    .OvalImage{
        @media (min-width: 992px) {
            padding-bottom: 18% !important;
        }
    }
    .backgroundImg{
        @media (min-width: 1200px) {
            max-width: 1190px;
        }
        @media (min-width: 1600px) {
            max-width: 1210px;
        }
    }
}