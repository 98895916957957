#jobs-section5 {
    margin-top: 0 !important;
        //transform: translateY(-30%);
    .buttonOvalImage{
        //transform: translateY(28%);
        .title-triangle{
            &.top-md{
                border-top: 15px solid #fff;
            }
        }
        h2{
            color: white;
        }
        .OvalImage{
            .backgroundImg{
                #oval-laranja{
                    transform: translateX(-10%);
                }
                @media (min-width: 992px){
                    transform: translate(14%, -2.5%) scale(1.1);
                    width: 74%;
                    max-width: 735px;
                    
                    #oval-laranja-rotacao{
                        transform: translate(5%,3%);
                    }
                }
                @media (min-width:1200px){
                    transform: translate(12%, -4%) scale(1.1);
                    width: 70%;
                    max-width: 840px;
                    #oval-laranja-rotacao{
                        transform: translateX(10%);
                    }
                }
            }
            @media (min-width: 992px) {
                padding-top: 8%;
                .grey-degrade{
                    margin-left: 60px;
                }
                img{
                    margin-left: 0px;
                    margin-top: 50px; 
                }
            }
            .grey-degrade{
                padding: 17px !important;
            }
        }

        .my-4{
            margin-top: 8px !important;
            margin-bottom: 8px !important;
        }
    }
}
