.header.ie{
    .iconG, .iconM{
        width: 20px;
    }
    nav > button{
        transform: translateY(-20px);
        &:first-child{
            transform: translate(-70px,-20px);
        }
    }
    @media (max-width: 1199px) {
        nav > button{
            right: 0;
            &:first-child{
                transform: translate(-70px,-20px);
            }
        }
    }
    .filter-invert{
        &::before{
            background: $black;
            content: " "; 
            position: absolute;
            width: 100vw;
            height: 100%;
            left: 0;
            top: 0;
        }
    }
    .locale{
        background: rgba(247, 247, 248,.7);
        @media (min-width: 1200px) {
            transform: translate(0, -20px);
        }
        @media (max-width: 1199px) {
            transform: translate(0, -15px);
            right: 0;
        }
    }
}
main{
    &.ie{
        .banner{
            .bottom-mountain,.image-banner-right .grey-mountain,.sliver-mountain,.light-blue-mountain{
                display: none;
            }
            p{
                white-space: normal !important;
            }
            &.blogBanner {
                max-height: none;
                min-height: none;
                .white-mountain::before{
                    background-color: $black;
                }
                .black-mountain-shadow{
                    top: 142px;
                }
                .bottom-mountain{
                    display: block;
                    max-height: none;
                }
            }
        }
        #contacts-banner{
            height: auto;
            max-height: none;
        }
        #homepage-section7{
            .IconTitleButton .IconTitleButtonItem h3{
                width: 100%;
            }
        }
        #homepage-section7{
            &::before{
                top:0;
            }
            h2:not(.h4){
                width: 100%;
            }
        }
        .cta{
            p{
                width: 100%;
            }
            .polygon{
                height: 200px !important;
                top: -46px !important;
                left: 0;
                @media ( min-width: 1600px ) {
                    height: 250px !important;
                    top: -71px !important;
                }
            }
        }
        .blogItem > div{
            display: block !important;
        }
        .modal{
            white-space: pre-line;
            a{
                &.green{
                    &:hover{
                        color: $green;
                        font-weight: 700;
                    }
                }
            }
        }
        #meetingRoom-section3,#consulting-section2,#quality-section3{
            .d-flex{
                width: 100%;
            }
            h2{
                white-space: pre-line;
                width: 100%;
            }
        }
        .dashedSection .itemDashed {            
            .img .backgroundImg{
                transform: translate(0, -2%) scale(1.4);
            }
            &.pr .img{
                .backgroundImg{
                    transform: translate(-10%, -2%) scale(1.4);
                }
                img{
                    transform: translate(6%, -2%) scale(1.4) !important;
                }
            }
            &::after{
                content: none !important;
            }

        }
        .square-icon{
            height: 80px;
        }
        .buttonOvalImage{
            @media (min-width:768px) {
                .d-md-flex{
                    display: block !important;
                }
            }
        }
        .policy-page{
            &::before{
                height: 142px !important;
                background-color: $black !important;
            }
            &::after{
                top: 82px !important;
            }
        }
        #jobs-section8 iframe {
            margin-top: auto;
        }
        #jobs-section5{
            .grey-degrade{
                box-shadow: none !important;
            }
            .buttonOvalImage .OvalImage .backgroundImg{
                transform: translate(6%, -16%) scale(1.1);
            }
        }
    }
    &.ie ~ #cookies-bottom-banner{
        min-height: auto !important;
    } 

}