#cookies-bottom-banner{
    width: 100%;
    background-color: black;
    position: fixed;
    z-index: 200;
    min-height: 500px;
    clip-path: polygon(0 25%, 100% 10%, 100% 100%, 0% 100%);
    bottom: 0;
    margin:0 ;
    @media(min-width:768px){
        min-height: 400px;
        clip-path: polygon(0 45%, 100% 20%, 100% 100%, 0% 100%);
    }
    p, .p{
        font-size: 12px;
    }
    a{
        &.white{
            text-decoration: underline;
        }
        &:hover{
            color: $orange;
            text-decoration: underline;
        }
    }
}