.IconTitleText{
    a{
        color: $grey;
        &.focus,&:hover{
            text-decoration: none;
            font-weight: 600;
        }
    }
    h3{
        span{
            color: $green;
        }
    }
    .icon{
        width: 60px;
        margin-right: 20px;
    }
    .content{
        margin-top: 20px;
    }
    .title-triangle{
        margin-left: 20px;
    }
}