.square-icon{
    max-width: 80px;
    min-width: 50px;
    border-radius: 8px;
    background: transparent linear-gradient(136deg, #FFFFFF 0%, #E7E8EB 100%) 0% 0% no-repeat padding-box;
    box-shadow: 5px 5px 11px #6565654A, -4px -4px 11px  #FFFFFF84;
    //border: 0.2px solid transparent;
    img{
        width: 100%;
        max-height: 100%;
    }
    @media ( max-width: 767px ) {
        max-height: 60px;
        max-width: 50px;
    }
    @media ( min-width: 768px ) {
        min-width: 60px;
    }
    @media ( min-width: 992px ) {
        min-width: 80px;
    }
}
.diamond-top-icon, .diamond-botton-icon{
    filter: drop-shadow( -3px -3px 5px #FFFFFF84) drop-shadow(3px 3px 5px #6565654A);
    flex-shrink: 0;
    .icon{
        max-width: 60px;
        min-width: 40px;
        background: transparent linear-gradient(136deg, #FFFFFF 0%, #E7E8EB 100%) 0% 0% no-repeat padding-box;
        box-shadow: 5px 5px 11px #6565654A;
        border: 0.2px solid #fff; 
        img{
            width: 100%;
        } 
        @media ( min-width: 576px ) {
            min-width: 50px;
            max-width: 70px
        }
        @media ( min-width: 768px ) {
            min-width: 60px;
            max-width: 80px
        }
        @media ( min-width: 992px ) {
            min-width: 80px;
        }
    }
}
.diamond-botton-icon{
    .icon{
        padding-bottom: 0.5rem; 
        clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
    }
}
.diamond-top-icon{
    .icon{
        padding-top: 0.5rem; 
        clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%);
    }

}
.dashedSection{
    .itemDashed{
        .img{
            position: relative;
            height: 100%;
            min-width: 72px;
            min-height: 72px;
            border-radius: 20px;
            background: transparent linear-gradient(136deg, #FFFFFF80 0%, #E7E8EB 100%) 0% 0% no-repeat padding-box;
            box-shadow: 3px 3px 5px #6565654A;
            .backgroundImg{
                position: absolute;
                height: 100%;
                max-height: unset;
                left: 0%;
                top: 0;
                transform: translate(-2%, -2%) scale(1.6);
                z-index: 0;
                @-moz-document url-prefix() {
                    @media(max-width:575px){
                        width: 150%;
                        transform: translate(-15%,-2%) scale(1.6);
                    }
                    @media(min-width:576px) and (max-width:767px){
                        transform: translate(-4%,-2%) scale(1.6);
                    }
                }
            }
            img{
                max-height: 100px;
                position: relative;
                z-index: 1;
                height: 100%;
                @media ( min-width: 576px ) {
                    min-width: 50px;
                    max-width: 70px
                }
                @media ( min-width: 768px ) {
                    min-width: 60px;
                    max-width: 80px
                }
                @media ( min-width: 992px ) {
                    min-width: 80px;
                }
            }
        }
    }
}