.Team {
    position: relative;
    z-index: 0;

    .team-members {
        position: relative;
        margin-top: 20px;
        padding: 5px 20px 40px 20px;
        z-index: 0;

        @media (min-width:375px) {
            padding: 5px 30px 75px 30px;
        }

        @media (min-width:425px) {
            padding: 5px 50px 75px 50px;
        }

        @media (min-width:768px) {
            padding: 0px 60px 40px 60px;
        }

        @media (min-width:992px) {
            padding: 0px 75px 55px 75px;
        }

        &::before {
            z-index: -1;
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: $white;

            clip-path: polygon(35% 0%, 45% 10%, 100% 9%, 96% 97%, 0% 99%, 3% 0%);

            @media (min-width:500px) {
                clip-path: polygon(35% 0%, 40% 7%, 100% 6%, 95% 97%, 0% 99%, 5% 0%);
            }

            @media (min-width:992px) {
                clip-path: polygon(35% 0%, 38% 5%, 100% 4%, 95% 97%, 0% 99%, 5% 0%);
            }
        }

        &::after {
            z-index: -2;
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: $light-orange;
            transform: scale(-1, 1);
            clip-path: polygon(35% 0%, 45% 10%, 100% 9%, 96% 97%, 0% 99%, 3% 0%);

            @media (min-width:500px) {
                clip-path: polygon(35% 0%, 40% 7%, 100% 6%, 95% 97%, 0% 99%, 5% 0%);
            }

            @media (min-width:992px) {
                clip-path: polygon(35% 0%, 38% 5%, 100% 4%, 95% 97%, 0% 99%, 5% 0%);
            }
        }

        &.recruitment {
            &::before {
                z-index: -2;

                @media (min-width:992px) {
                    clip-path: polygon(35% 0%, 38% 5%, 100% 4%, 95% 97%, 0% 99%, 5% 0%);
                }

            }

            &::after {
                z-index: -1;

                @media (min-width:992px) {
                    clip-path: polygon(35% 0%, 38% 5%, 100% 4%, 95% 97%, 0% 99%, 5% 0%);
                }
            }

            a {
                &:hover {
                    color: $white;

                    &:before {
                        content: url('../../../assets/images/team/icon-email-branco.svg');
                    }
                }
            }
        }

        .sales-group,
        .recruitment-group {
            padding: 0;
        }

        .tab-text-group {
            @media (min-width:320px) and (max-width:424px) {
                padding: 0 15px;
            }

            @media (min-width:1440px) {
                padding: 0 25px;
            }

            .tab-text {
                font-size: 16px;
                text-align: center;
                color: $black;
                cursor: pointer;

                @media (min-width:768px) {
                    font-size: 18px;
                }

                @media (min-width:992px) {
                    font-size: 25px;
                }
            }
        }
    }

    .backgroundImg {
        display: none;
        max-height: 900px;

        @media (min-width: 992px) {
            display: initial;
            position: absolute;
            height: 110%;
            left: 50%;
            transform: translate(-50%, -12%);
            z-index: 0;
        }
    }

    h2 {
        position: relative;

        &::before {
            content: " ";
            position: relative;
            left: 10%;
            width: 0;
            height: 0;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            border-top: 15px solid $black;

            @media(min-width:768px) {
                left: 5%;
            }
        }

        &::after {
            content: " ";
            position: relative;
            right: 10%;
            bottom: 0;
            width: 0;
            height: 0;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-bottom: 20px solid $black;
            opacity: 0;

            @media(min-width:992px) {
                right: 5%;
            }

            @media(min-width:768px) {
                opacity: 1;
            }
        }
    }

    .teamItem {
        position: relative;
        z-index: 0;
        padding-bottom: 20px;

        @media (min-width:425px) {
            padding-bottom: 0px;
            margin-bottom: 30px;
        }

        @media (min-width:992px) {
            height: 464px;
        }

        @media (min-width:1200px) {
            height: 544px;
        }

        &::before {
            z-index: -1;
            content: "";
            background-image: url('../../../assets/images/team/Moldura.svg');
            background-repeat: no-repeat;
            background-size: contain;
            width: 80%;
            height: 50%;
            position: absolute;
            top: 30px;
            left: 0;
        }

        .teamItem-content {
            padding: 45px 20px 0px 20px;

            .identification {
                margin-bottom: -20px;
                margin-left: -5px;
                height: 60px;

                @media (min-width:768px) {
                    height: 70px;
                    margin-bottom: -30px;
                }

                h5,
                p {
                    @include font-size(12);
                    line-height: 1.2;
                    padding-right: 134px;

                    @media(min-width:375px) {
                        @include font-size(14);
                    }

                    @media (min-width: 768px) {
                        padding-right: 135px;
                    }

                    @media (min-width: 992px) {
                        padding-right: 105px;
                    }

                    @media (min-width: 1200px) {
                        padding-right: 130px;
                    }
                }
            }

            .colaborador {
                padding: 25px 20px 25px 20px;
                position: relative;

                &::before {
                    z-index: -2;
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    clip-path: polygon(0 0, 100% 0, 100% 85%, 0 95%);
                    background: transparent linear-gradient(138deg, #FFFFFF 0%, #E7E8EB 100%) 0% 0% no-repeat padding-box;
                    border: 0.2px solid #FFFFFF;
                }
            }
        }
    }

    .info {
        position: relative;
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 5px;

        @media (max-width: 374px) {
            max-width: 230px;
        }

        @media (min-width: 375px) and (max-width: 767px) {
            max-width: 290px;
        }

        @media(min-width:768px) {
            left: 5%;

        }

        a {
            display: flex;

            &:hover {
                color: $orange;
                text-decoration: none;

                &:before {
                    content: url('../../../assets/images/team/icon-email-laranja.svg');
                }
            }

            &:before {
                content: url('../../../assets/images/team/icon-email-verde.svg');
                margin-right: 5px;
                width: 15px;
                height: 15px;
                font-weight: 600;
                flex-shrink: 0;

                @media(min-width: 1200px) {
                    width: 20px;
                    height: 20px;
                }

            }
        }
    }

    .img-dashed-t {
        position: absolute;
        width: 22vw;
        z-index: 2;
        right: 0;
        top: -110px;
    }

    .img-dashed-b {
        position: absolute;
        width: 30vw;
        right: 0;
        bottom: -210px;

        @media (max-width: 1299px) {
            bottom: -180px;
        }
    }

    .slick-dots {
        @media(max-width:374px) {
            bottom: -25px;
        }

        @media(min-width: 375px) and (max-width:575px) {
            bottom: -40px;
        }

    }
}