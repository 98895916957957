.blogUI {

    a,
    .blogItemUI {
        width: 100%;
        height: 100%;
    }

    .slick-list {
        @media (max-width: 767px) {
            margin-left: -15px;
        }

        .slick-slide {
            @media (min-width: 768px) {
                padding-right: 5px;
                padding-left: 17px;
            }
        }
    }

    a {

        &:hover,
        &:focus {
            text-decoration: unset;
        }
    }

    .blogItemUI {
        padding: 30px 10px;
        position: relative;

        @media(min-width:768px) {
            padding: 60px 10px;
        }

        @media (max-width: 767px) {
            padding-right: 0;
            transform: translateX(5px) scale(0.95);
        }

        &:hover {
            opacity: .8;
        }

        .img {
            position: relative;

            &::before {
                content: " ";
                position: absolute;
                width: 100%;
                left: -12px;
                top: -12px;
                height: 100%;
                clip-path: polygon(75% 0, 85% 20%, 100% 100%, 0 100%, 0% 50%, 0 1%);
                background: $black;
            }

            img {
                width: 100%;
                position: relative;
            }
        }

        &.bg {
            filter: drop-shadow(5px 5px 11px #6565654A) drop-shadow(-5px -5px 11px #FFFFFF84);

            &::before {
                content: " ";
                position: absolute;
                width: calc(100% - 8px);
                height: calc(100% - 60px);
                top: 60px;
                left: -2px;
                clip-path: polygon(100% 0, 100% 85%, 0 100%, 0 0);
                background: $white;

                @media (max-width: 767px) {
                    width: calc(100% + 10px);
                }
            }

            @media (max-width: 575px) {
                p {
                    &:not(.full-date) {
                        padding-bottom: 30px;
                    }
                }
            }
        }

        h2 {
            margin: 15px 0;
            @include font-size(14)
        }

        h2, p {
            position: relative;
            color: $black;
            padding-right: 10px;
        }

        .full-date {
            color: $green;
            font-size: 13px;
        }
    }
}