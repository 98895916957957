.ThankYouPage {
    padding-top: 161px;
    position: relative;
    z-index: 0s;
    /* display: flex;
    align-items: start;
    justify-content: start; */

    .banner-span {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;

        span {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            clip-path: unset;
            background-color: unset;
            z-index: 0;

            &::before {
                content: " ";
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                position: absolute;
            }
        }

        .black-mountain {
            &::before {
                clip-path: polygon(25% 20%, 100% 35%, 100% 100%, 0 100%, 0 37%);
                background-color: #000;

                @media (max-width:575px) {
                    clip-path: polygon(25% 20%, 100% 30%, 100% 100%, 0 100%, 0 30%);
                }

                @media (max-width:1023px) {
                    clip-path: polygon(25% 20%, 100% 30%, 100% 100%, 0 100%, 0 30%);
                }
            }
        }
    }

    .content {
        padding-top: 70px;
        height: 550px;
        position: relative;
        z-index: 2;

        @media (max-width:575px) {
            padding-bottom: 80px;
            height: 600px;

            img {
                width: 40%;
            }
        }

        @media (min-width:550px) and (max-width:767px) {
            padding-bottom: 80px;
            height: 650px;

            img {
                width: 40%;
            }
        }

        @media (min-width:768px) {
            padding-bottom: 80px;
            height: 600px;

            img {
                width: 100%;
            }
        }

        @media (min-width:992px) {
            padding-bottom: 80px;
            height: 550px;

            img {
                width: 100%;
            }
        }

        .title {
            font-size: 34px;
            color: #FF8618;
            font-weight: 600;
            text-transform: uppercase;
            margin-bottom: 1rem;

            @media (max-width:767px) {
                font-size: 21px;
                margin-top: 10px;
            }
        }

        .text {
            color: $white;
            font-size: 20px;
            font-weight: 400;
            word-wrap: break-word;
            padding-right: 25%;

            @media (max-width:767px) {
                font-size: 14px;
                padding-right: 0;
            }
        }

        .button {
            height: 0;
            width: 0;
            position: relative;
            z-index: 102;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px;
            background: transparent linear-gradient(109deg, #FFFFFF 0%, #E7E8EB 100%) 0% 0% no-repeat padding-box;
            border: 0.2px solid transparent;
            box-shadow: none;
            border-radius: 50px;
            margin: 0 15px 0 20px;
            transition: all 0.3s ease-out;

            &.active {
                -webkit-transform: scale(1.05);
                -ms-transform: scale(1.05);
                transform: scale(1.05);
                color: #dd5100;
                background: rgba(0, 0, 0, 0) linear-gradient(-109deg, #fff, #e7e8eb) 0 0 no-repeat padding-box;
                border-color: #fff;
            }

            img {
                position: absolute;
            }
        }

        .socials {
            .button {
                margin: 0 10px 0 0;
            }

            img {
                height: 30px;
                width: 30px;
            }
        }
    }

    .second-mountain {
        width: 100%;
        position: absolute;
        z-index: 1;
        bottom: 99px;
        left: 0;
        padding: 0 15%;

        @media (max-width:575px) {
            padding: 0 5%;
            height: 100px;
        }

        @media (min-width:576px) {
            padding: 0 8%;
            height: 100px;
        }

        @media (min-width:768px) {
            padding: 0 10%;
        }

        @media (min-width:992px) {
            padding: 0 12%;
        }

        @media (min-width:1024px) {
            padding: 0 14%;
        }

        img {
            position: relative;
            z-index: 2;

            @media (max-width:575px) {
                width: 28%;
            }

            @media (min-width:576px) {
                width: 30%;
            }

            @media (min-width:768px) {
                width: 23%;
            }

            @media (min-width:992px) {
                width: 18%;
            }

            @media (min-width:1024px) {
                width: 15%;
            }
        }

        span {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            clip-path: unset;
            background-color: unset;
            z-index: 0;

            &::before {
                content: " ";
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                position: absolute;
            }
        }

        .blue-mountain {
            &::before {
                clip-path: polygon(80% 0%, 100% 40%, 100% 100%, 0 100%, 0 90%);
                background-color: #8BD5D1;

                @media (max-width:1023px) {
                    clip-path: polygon(80% 0%, 100% 20%, 100% 100%, 0% 100%, 0 30%);
                }

                @media (min-width:1920px) {
                    clip-path: polygon(73% 0%, 100% 40%, 100% 100%, 0 100%, 0 90%);
                }

                @media (min-width:2560px) {
                    clip-path: polygon(69% 0%, 100% 40%, 100% 100%, 0 100%, 0 90%);
                }
            }
        }
    }

    .third-mountain {

        width: 100%;
        height: 100px;
        position: absolute;
        z-index: 9;
        bottom: 0;
        left: 0;
        padding: 0 15%;
        background-color: #8BD5D1;

        @media (max-width:575px) {
            padding: 0 5%;
        }

        @media (min-width:576px) {
            padding: 0 8%;
        }

        @media (min-width:768px) {
            padding: 0 10%;
        }

        @media (min-width:992px) {
            padding: 0 12%;
        }

        a {
            position: relative;
            z-index: 2;
        }

        span {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            clip-path: unset;
            background-color: unset;
            z-index: 0;

            &::before {
                content: " ";
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                position: absolute;
            }
        }

        .orange-mountain {
            &::before {
                clip-path: polygon(100% 40%, 100% 100%, 0% 100%, 0% 100%, 0% 0%);
                background-color: #f18424;

                @media (max-width:1023px) {
                    clip-path: polygon(100% 20%, 100% 100%, 0% 100%, 0% 100%, 0% 0%);
                }
            }
        }

        button {
            margin: 0 !important;
        }
    }

    &~footer {
        margin-top: 50px !important;
    }
}