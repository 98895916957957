#contacts-banner {
    position: relative;
    height: unset;
    max-height: unset;
    overflow: unset;

    .bottom-mountain {
        display: none;
    }

    #form {
        margin-top: 10vh;

        @media(min-width:768px) {
            margin-top: 15vh;

            @-moz-document url-prefix() {

                input[name="name"],
                input[name="email"] {
                    margin-bottom: 40px;
                }
            }
        }

        @media(min-width:992px) {
            margin-top: 10vh;
        }

        @media (min-width: 1200px) {
            @-moz-document url-prefix() {

                input[name="name"],
                input[name="email"] {
                    margin-bottom: 37px;
                }
            }
        }

        &.animation {
            transform: translateY(200vh);

            &.start {
                transform: translateY(0);
            }
        }

        .title-triangle {
            margin-left: 40px;
        }

        .button {
            border: unset;
        }
    }

    .black-mountain-shadow {
        &::after {
            content: " ";
            position: absolute;
            top: 99%;
            width: 100%;
            height: 200px;
            background: black;
        }
    }

    .black-mountain {
        clip-path: polygon(20% 10%, 100% 15%, 100% 100%, 0 100%, 0 15%);

        @media (min-width: 768px) {
            clip-path: polygon(35% 15%, 100% 30%, 100% 100%, 0 100%, 0 20%);
        }

        @media (min-width: 992px) {
            clip-path: polygon(30% 15%, 100% 30%, 100% 100%, 0 100%, 0 25%);
        }
    }

    .white-mountain {
        &::before {
            content: " ";
            top: 0;
            height: 100%;
            clip-path: polygon(100% 20%, 100% 100%, 0 100%, 0 0, 37% 0);

            @media(min-width:768px) {
                clip-path: polygon(100% 36%, 100% 100%, 0 100%, 0 0, 33% 0);
            }

            @media (min-width: 1600px) {
                clip-path: polygon(100% 66%, 100% 100%, 0 100%, 0 0, 39% 0);
            }
        }
    }
}

#contacts-section2 {
    position: relative;
    background: $silver;

    h2 {
        position: relative;
    }

    &::before {
        top: -59px;
        left: 0;
        content: " ";
        position: absolute;
        width: 100vw;
        height: 60px;
        background: $silver;
        clip-path: polygon(0 0, 0% 0%, 100% 100%, 0 100%);
    }

    &::after {
        bottom: -59px;
        left: 0;
        content: " ";
        position: absolute;
        width: 100vw;
        height: 60px;
        background: $silver;
        clip-path: polygon(0 0, 100% 0, 0 100%);
    }

    .IconTitleText .content {
        margin-top: 0;

        .call {
            font-weight: 400;
            line-height: 1;
            font-size: 55%;
        }

        @media(min-width: 768px) {
            min-height: 160px;
        }

        @media(min-width:992px) {
            min-height: 142px;

            .call {
                font-size: 65%;
            }
        }
    }
}

#contactsModal {

    .black,
    a {
        color: $black !important;
    }
}