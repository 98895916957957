.shadow-blog{
    box-shadow: 10px 20px 25px #00000031;
}

.slick-dots li button:before{
    font-size: 16px;
    line-height: 16px;
    @media ( max-width: 575px ) {
        font-size: 12px;
        line-height: 12px;
    }
}

#blog{
    position: relative;
    .blog-bg{
        position: absolute;
        z-index: 0;
        top: -30%;
        @media(min-width: 992px){
            top: -30%;
            width: 100%;
        }
        @media(min-width: 1300px){
            top: -70%;
            left: 2.5%;
            max-width: 95%;
        }
        @media(min-width: 1800px){
            max-width: 70%;
            top: -70%;
            left: 15%;
        }
    }
    .p,p{
        @media (min-width: 1200px) {
            @include font-size(14)
        }
        &.date{
            @include font-size(10)
        }
    }
    .blog-content{
        position: relative;
        @media ( max-width: 767px ) {
            .h4{
                text-align: center;
            }
            .title-triangle{
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
    .slick-prev, .slick-next{
        top: 25%;
    }
}