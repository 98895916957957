#attendenceManagement-section4{
    position: relative;
    @media (min-width: 992px) {
        margin-top: 100px;
        margin-bottom: 50px;
    }
    .title-triangle{
        margin-left: 120px;
        position: relative;
        @media ( min-width: 768px ) {
            margin-left: 220px;
        }
        @media ( min-width: 992px ) {
            margin-left: 320px;
        }
        @media ( min-width: 1200px ) {
            margin-left: 380px;
        }
    }
    h2{
        position: relative;
        text-align: center;
    }
    .backgroundImg{
        display: none;
        @media (min-width: 992px) {
            display: initial;
            position: absolute;
            height: 160%;
            left: 50%;
            transform: translate(-50%, -13%) scale(1.3) rotate(5deg);
            z-index: 0;
            top:30%
        }
    }
}