.banner {
    height: 100vh;
    max-height: 1080px;
    overflow: hidden;
    position: relative;

    .spanOrange {
        color: #DD5100;
    }

    &.services-banner,
    &.products-banner {
        max-height: 800px;

        .title,
        .content {
            padding-bottom: 0;

            h1 {
                @media (min-width: 576px) {
                    letter-spacing: 2px;
                }
            }
        }

        @media (max-width: 991px) {
            height: auto;
            min-height: unset;

            .title {
                padding-top: 10%;
                padding-bottom: 25% !important;
            }

            .content {
                padding-bottom: 5%;
            }

            .image-banner-right {
                bottom: -37vw;
                max-width: 115vw;
                left: -15vw;

                img {
                    width: 150vw;
                }

                .grey-mountain {
                    display: none;
                }
            }
        }

        @media (max-width: 767px) {
            .image-banner-right {
                bottom: -51vw;
                max-width: 150vw;
                left: -50vw;

                img {
                    width: 150vw;
                }
            }
        }

        @media (max-width: 575px) {
            .title {
                padding-top: 20%;
            }

            .image-banner-right {
                bottom: -40vw;
            }
        }

        @media(min-width: 768px) and (max-width:1199px) {
            max-height: 650px;
        }

    }

    &#outsourcing-banner.services-banner {
        @media(min-width: 768px) and (max-width:991px) {
            max-height: 750px;
        }

        .title {
            @media (max-width: 991px) {
                padding-bottom: 0 !important;
            }

            @media (max-width: 575px) {
                iframe {
                    padding-top: 0 !important;
                }
            }
        }
    }

    min-height: 500px;

    @media (min-width: 768px) {
        min-height: 650px;
    }

    .row,
    .container {
        height: 100%;
    }

    .title {
        align-self: center;

        .p,
        .h4,
        .h5 {
            white-space: break-spaces;
        }

        white-space: break-spaces;
        /* @media (min-width: 992px) {
            white-space: nowrap;
        } */
    }

    .content {
        align-self: flex-end;
        overflow: initial !important;
    }

    .title,
    .content {
        &.animation {
            opacity: 0;
            transform: translateY(100vh);
        }

        &.start {
            opacity: 1 !important;
            transform: translateY(0);
        }
    }

    @media (orientation: portrait) {
        height: auto;
    }

    @media (min-width: 1600px) {
        background-position: 52% 6% !important;

        .title,
        .content {
            padding-bottom: 10%;
        }
    }

    @media (max-aspect-ratio: 4/3) {
        height: 75vh;
    }

    //mountains banner products and services
    .black-mountain-shadow {
        width: 100vw;
        height: 100%;
        position: absolute;
        top: 0;

        &.shadow {
            filter: drop-shadow(0px -10px 15px #00484841);
        }

    }

    .black-mountain {
        width: 100vw;
        height: 100%;
        background-color: black;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        clip-path: polygon(30% 20%, 100% 25%, 100% 100%, 0 100%, 0 25%);

        @media (min-width: 768px)and (max-width:991px) {
            //top: 30px;
        }

        @media (min-width: 450px) {
            clip-path: polygon(30% 22%, 100% 30%, 100% 100%, 0 100%, 0 30%);
        }

        @media (min-width: 1200px) {
            clip-path: polygon(30% 20%, 100% 30%, 100% 100%, 0 100%, 0 30%);
        }
    }

    .bottom-mountain {
        &::before {
            content: " ";
            width: 100vw;
            height: 20px;
            position: absolute;
            clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
            bottom: -1px;
            left: 0;
            z-index: 0;

            @media(min-width:992px) {
                height: 40px;
            }
        }

        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 0;
        height: 60px;

        &.shadow {
            filter: drop-shadow(0px -10px 15px rgba(0, 0, 0, 0.3));
        }

    }

    .white-mountain {
        &::before {
            content: " ";
            width: 100vw;
            height: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: white;
            clip-path: polygon(100% 28%, 100% 100%, 0 100%, 0 0, 29% 0);

            @media(min-width:768px) {
                clip-path: polygon(100% 36%, 100% 100%, 0 100%, 0 0, 33% 0);
            }

            @media (min-width: 1600px) {
                clip-path: polygon(100% 66%, 100% 100%, 0 100%, 0 0, 39% 0);
            }
        }

        &.shadow {
            filter: drop-shadow(0px -10px 20px rgba(0, 0, 0, 0.3));
        }

        height: 100%;
        width: 100vw;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;

    }

    .image-banner-right {
        position: absolute;
        right: 0;
        bottom: 0;
        max-width: 55%;
        z-index: 0;
    }

    .grey-mountain {
        clip-path: polygon(86% 0%, 100% 55%, 100% 100%, 0% 100%);
        background-color: black;
        opacity: 0.6;
        width: 50%;
        height: 80%;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 1;
    }

    .animation .animation,
    .animation span {
        transform: translateY(200vh);
    }

    .animation.start .animation {
        transform: translateY(200vh);
        transition: transform .6s .4s cubic-bezier(0.07, 0.06, 0.26, 0.83), filter .2s ease-out;

        &:nth-child(1) {
            transform: translateY(0);
            transition: unset;
        }

        &:nth-child(2),
        &:nth-child(2) span {
            transform: translateY(0);
            transition: transform .6s .4s cubic-bezier(0.07, 0.06, 0.26, 0.83), filter .2s ease-out;

        }

        &:nth-child(3) {
            transform: translateY(0);
            transition: transform .6s 1.8s cubic-bezier(0.07, 0.06, 0.26, 0.83), filter .2s ease-out;

            span {
                transform: translateY(0);
                transition: transform .6s 2.2s cubic-bezier(0.07, 0.06, 0.26, 0.83), filter .2s ease-out;
            }
        }

        &:nth-child(4),
        &:nth-child(4) span {
            transform: translateY(0);
            transition: transform .6s 2.6s cubic-bezier(0.07, 0.06, 0.26, 0.83), filter .2s ease-out;
        }
    }

    &.products-banner {

        p,
        .title {
            color: white;
            line-height: 1.8;

            strong {
                color: $yellow;
            }
        }

        .bottom-mountain {
            &::before {
                background-color: $yellow;
            }
        }
    }

    &.services-banner {

        p,
        .title {
            color: white;
            line-height: 1.8;

            strong {
                color: $blue;
            }

            &.red {
                color: $red;
            }
        }

        .bottom-mountain {
            &::before {
                background-color: $blue;
            }
        }
    }

    /*  .embed-responsive.embed-responsive-16by9 {
        @media (min-width: 992px) {
            margin-top: 160px;
        }

        @media (min-width: 1200px) {
            margin-top: 190px;
        }
    } */
}


//Exceptions

#shareholderMeeting-banner,
#development-banner,
#outsourcing-banner {
    /* @media(min-width:992px) {
        .title {
            margin-top: 70px;
        }
    } */

    @media(max-width:575px) {
        .black-mountain {
            clip-path: polygon(30% 16%, 100% 22%, 100% 100%, 0 100%, 0 22%);
        }
    }
}

.de #development-banner {
    @media (min-width: 800px) and (max-width: 991px) {
        .title {
            transform: translateY(-16px);
        }
    }
}

#homepage-banner,
#jobs-banner {
    h1 {
        strong {
            color: $white;
        }
    }

    .p {
        @media (min-width: 1200px) {
            @include font-size(18);
        }
    }

    div.content {
        div.title span i {
            width: 115%;
        }

        @media(min-width: 576px) {
            width: 170%;
        }
    }
}

#jobs-banner {
    @media(max-width:767px) {
        .title {
            align-self: flex-end;
        }
    }
}

#meetingRoom-banner,
#comercialPaper-banner {
    h1 {
        white-space: normal;
    }
}

#outsourcing-banner {
    @media (max-width:991px) {
        max-height: none !important;

        .title {
            padding-top: 20%;
        }
    }
}

#shareholderMeeting-banner {
    @media(min-width: 1600px) {
        h1 {
            white-space: break-spaces;
        }
    }
}