.policy-page{
    position: relative;
    filter: drop-shadow( 0px -10px 20px #0000002C);
    &::before{
        content: "";
        position: absolute;
        top: -60px;
        left: 0;
        width: 100vw;
        height: 255px;
        clip-path: polygon(40% 0, 100% 90%, 100% 100%, 0 100%, 0 0);
        background-color: $light-silver;
    }
    &:after{
        content: "";
        position: absolute;
        bottom: -100px;
        left: 0;
        width: 100vw;
        height: 150px;
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
        background-color: $light-silver;
    }
    .container{
        margin-top: 80px;
        z-index:10;
        position: relative;
        a{
            &:hover{
                color: $black;
            }
        }
    }
}