#homepage-banner {
  .banner-span {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    span {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      clip-path: unset;
      background-color: unset;
      z-index: 0;

      &::before {
        content: " ";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
      }
    }

    .ligthBlue-mountain {
      transform: translateY(0) !important;

      &.shadow {
        filter: drop-shadow(0px -10px 15px rgba(0, 0, 0, 0.3));
      }

      &::before {
        background-color: #8BD5D1;
        clip-path: polygon(100% 45%, 100% 100%, 0 100%, 0 0, 29% 0);

        @media(min-width: 768px) {
          clip-path: polygon(100% 66%, 100% 100%, 0 100%, 0 0, 33% 0);
        }

        @media (min-width: 1600px) {
          clip-path: polygon(100% 66%, 100% 100%, 0 100%, 0 0, 39% 0);
        }
      }
    }

    .blue-mountain {
      &.shadow {
        //filter:drop-shadow(0px -2px 0px #59bdb9) drop-shadow(0px -20px 30px rgba(0, 0, 0, 0.3));
      }

      &::before {
        clip-path: polygon(100% 32%, 100% 100%, 0 100%, 0 34%, 15% 22%);
        background-color: #007b7b;
      }
    }

    .yellow-mountain {
      &.shadow {
        //filter: drop-shadow(0px -20px 30px rgba(0, 0, 0, 0.3));
      }

      &::before {
        clip-path: polygon(100% 100%, 0 100%, 0 79%, 100% 23%);
        background-color: #FFCA31;
        // opacity: 0.64;
      }
    }

    .black-mountain {
      &.shadow {
        //filter:drop-shadow(0px -2px 0px #8fcfcd) drop-shadow(0px -20px 30px rgba(0, 0, 0, 0.3));
      }

      &::before {
        clip-path: polygon(25% 31%, 100% 52%, 100% 100%, 0 100%, 0 45%);
        background-color: #000;
      }
    }

    .orange-mountain {
      &.shadow {
        filter: drop-shadow(0px -10px 15px rgba(0, 0, 0, 0.3));
      }

      &::before {
        clip-path: polygon(73% 50%, 100% 68%, 100% 100%, 0 100%, 0 95%);
        background-color: #f18424;
      }
    }

    .sliver-mountain {
      &.shadow {
        filter: drop-shadow(0px -5px 20px rgba(0, 0, 0, 0.3));
      }

      &::before {
        clip-path: polygon(100% 98%, 100% 100%, 0 100%, 0 96%, 22% 91%);
        background-color: #e4e4e4;
      }
    }

    .animation {
      transform: translateY(100vh);
    }
  }

  &.inView {
    .banner-span .animation {
      &:nth-child(2) {
        transform: translateY(0);
        transition: transform .6s .5s cubic-bezier(0.07, 0.06, 0.26, 0.83), filter .1s;
      }

      &:nth-child(3) {
        transform: translateY(0);
        transition: transform .6s 1s cubic-bezier(0.07, 0.06, 0.26, 0.83), filter .1s;
      }

      &:nth-child(4) {
        transform: translateY(0);
        transition: transform .6s 1.5s cubic-bezier(0.07, 0.06, 0.26, 0.83), filter .1s;
      }

      &:nth-child(5) {
        transform: translateY(0);
        transition: transform .6s 2s cubic-bezier(0.07, 0.06, 0.26, 0.83), filter .1s;
      }

      &:nth-child(6) {
        transform: translateY(0);
        transition: transform .6s 2.5s cubic-bezier(0.07, 0.06, 0.26, 0.83), filter .1s;
      }
    }
  }

  .content {
    @media (min-width: 1400px) and (min-height: 800px) {
      transform: translate(20%, -25%);
    }

    @media (min-width: 1500px) and (min-height: 999px) {
      transform: translate(5%, -50%);
    }
  }
}