@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');

html {
    font-size: 10px;
}

html,
body,
input,
button,
select,
optgroup,
textarea {
    font-family: 'Poppins', sans-serif;
}

/************************ WEIGHT *************************/
.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700;
}

.fw-800 {
    font-weight: 800;
}


h1,
.h1 {
    line-height: 1.5;
    @include font-size(20);

    @media(min-width: 576px) {
        @include font-size(26);
    }

    @media(min-width: 768px) {
        @include font-size(32);
    }

    @media(min-width: 1200px) {
        @include font-size(40);
    }
}

h2,
.h2 {
    line-height: 1.6;
    @include font-size(18);

    @media(min-width: 768px) {
        @include font-size(26);
    }

    @media(min-width: 1200px) {
        @include font-size(30);
    }
}

h3,
.h3 {
    line-height: 1.6;
    @include font-size(16);

    @media(min-width: 768px) {
        @include font-size(20);
    }

    @media(min-width: 1200px) {
        @include font-size(25);
    }
}

h4,
.h4 {
    line-height: 1.6;
    @include font-size(16);

    @media(min-width: 768px) {
        @include font-size(18);
    }

    @media(min-width: 1200px) {
        @include font-size(22);
    }
}

h5,
.h5 {
    line-height: 1.6;
    @include font-size(14);

    @media(min-width: 768px) {
        @include font-size(16);
    }

    @media(min-width: 1200px) {
        @include font-size(20);
    }
}

h6,
.h6 {
    line-height: 1.6;
    @include font-size(14);

    @media(min-width: 768px) {
        @include font-size(14);
    }

    @media(min-width: 1200px) {
        @include font-size(18);
    }
}

a,
p,
.p {
    line-height: 1.6;
    @include font-size(12);

    @media(min-width: 768px) {
        @include font-size(14);
    }

    @media(min-width:1200px) {
        @include font-size(16);
    }
}