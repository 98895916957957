#jobs-banner {
  background-color: #007B7B;
  .title{
    h1, .h5{
      white-space: break-spaces;
      color: #fff;
      strong{
        color: #FFCA31;
      }
    }
  }
  .banner-span{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    span{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      clip-path: unset;
      background-color: unset;
      z-index: 0;
      &::before{
        content: " ";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
      }
    }
    .white-mountain{
      transform: translateY(0) !important;
      &.shadow{
        filter:drop-shadow(0px -2px 0px #fff) drop-shadow(0px -10px 15px rgba(0, 0, 0, 0.3));
      }
      &::before{
        clip-path: polygon(100% 32%, 100% 100%, 0 100%, 0 0, 29% 0);
        @media(min-width:768px){
            clip-path: polygon(100% 46%, 100% 100%, 0 100%, 0 0, 29% 0);
        }
        @media ( min-width: 1600px ) {
          clip-path: polygon(100% 66%, 100% 100%, 0 100%, 0 0, 39% 0);
        }
      }

      
    }
    .black-mountain{
      &.shadow{
        filter:drop-shadow(0px -2px 0px #000) drop-shadow(0px -10px 15px rgba(0, 0, 0, 0.6));
      }
      &::before{
        clip-path: polygon(25% 21%, 100% 36%, 100% 100%, 0 100%, 0 30%);
        @media(min-width:768px){
          clip-path: polygon(26% 23%, 100% 47%, 100% 100%, 0 100%, 0 34%);
        }
        background-color: #000;
      }
    }
    .orange-mountain{
      &.shadow{
        //filter:drop-shadow(0px -2px 0px #f7be8a) drop-shadow(0px 10px 15px rgba(0, 0, 0, 0.3));
      }
      &::before{
        clip-path: polygon(100% 45%, 100% 100%, 0 100%, 0 75%);
        @media(min-width:768px){
          clip-path: polygon(73% 50%, 100% 68%, 100% 100%, 0 100%, 0 95%);
        }
        background-color: #f18424;
      }
    }
    .light-blue-mountain{
      &.shadow{
        filter:drop-shadow(0px -10px 10px rgba(97, 70, 70, 0.75));
      }
      &::before{
        clip-path: polygon(100% 98%, 100% 100%, 0 100%, 0 97%, 42% 93%);
        background-color: #e3f1f3;
        @media ( max-width: 991px ) {
          bottom: -2px;
          top: unset;
        }
      }
    }
    .animation{
      transform: translateY(200vh);
    }
    
  }
  
  &.inView .animation{
    &:nth-child(2){
      transform: translateY(0);
      transition: transform 1s 1s, filter .2s ease-out;
    }
    &:nth-child(3){
      transform: translateY(0);
      transition: transform 1s 2s, filter .2s ease-out;
    }
    &:nth-child(4){
      transform: translateY(0);
      transition: transform 1s 3s, filter .2s ease-out;
    }
    &:nth-child(5){
      transform: translateY(0);
      transition: transform 1s 4s, filter .2s ease-out;
    }
    &:nth-child(6){
      transform: translateY(0);
      transition: transform 1s 7s, filter .2s ease-out;
    }
  }    
}
.pt #jobs-banner,.de #jobs-banner{
  @media ( min-width: 768px ) and ( max-width: 991px ) {
    .title{
      transform: translateY( -50px );
    }
  }
}
