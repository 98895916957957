.tabBox{
    &.services{
        .titles{
            &.active{
                color: $orange;
            }
        }
    }
    &.products{
        .titles{
            &.active{
                color: $green;
            }
        }
    }
    .box{
        position: relative;
        z-index: 1;
        background: #F1F2F5 0% 0% no-repeat padding-box;
        border-radius: 16px;
        padding:23px;
        box-shadow: -5px -5px 10px #ffffffcb, inset -8px -8px 10px #00000070;
        .titles{
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            position: relative;
           
            &:not(:last-child)::after{
                background: #EAEBF3 0% 0% no-repeat padding-box;
                box-shadow: -1px -1px 2px #ffffffe6;
                content: " ";
                width: 100%;
                left: 0;
                bottom: -1.5rem;
                height: 2px;
                position: absolute;
            }
        }
        .col-md-8::after{
            content: " ";
            width: calc(100% - 46px);
            height: 100%;
            left: 46px;
            top: 0;
            position: absolute;
        }
    }

    .details{
        display: flex;
        align-items: center;
        flex-direction: column;
        min-height: 100%;
        .diamond-botton-icon{
            margin: 2rem 0;   
            min-width: 25%;         
        }
        p{
            margin: 0;
        }
    }
    .rcs-custom-scroll{
        min-height: 100%;
        &::before {
            display: none;
            left: 8px;
            right: unset !important;  
            @media (min-width: 768px){
                display: block;
            }         
        }
        .rcs-positioning{
            display: none;
            right: unset !important;
            left: 0;
            @media (min-width: 768px){
                display: block;
            }
        }
        .rcs-inner-handle{
            box-shadow: -1px -1px 8px #586C8A80 !important;     
            cursor: pointer;   
            pointer-events: initial;
        }
        .rcs-inner-container {
            overflow: hidden !important;
            padding-right: unset !important;
            padding-left: 0;
            box-shadow: -1px -1px 8px #24415D4D !important;     
            @media (min-width: 768px){
                padding-left: 46px;
            }   

            @media (max-width: 767px) {
                margin-left: 0 !important;
                padding: 0 !important;
                > div{
                    margin-left: 0 !important;
                    padding: 0 !important;
                }
            }
        }
        .rcs-custom-scroll-handle{
            transition: all 0.3s ease-in-out;
        }
    }
    
}