.slick-arrow {

    &.slick-prev,
    &.slick-next {
        &:before {
            display: block;

            @media(min-width:768px) {
                display: block;
            }

            color: $black;

            @media(min-width:992px) {
                font-size: 30px;
            }
        }
    }

    &.slick-prev {
        left: -10px;
    }

    &.slick-next {
        right: -10px;
    }

    @media(min-width:575px) {
        &.slick-prev {
            left: -25px;
        }

        &.slick-next {
            right: -25px;
        }
    }

    @media(min-width:1200px) {
        &.slick-prev {
            left: -40px;
        }

        &.slick-next {
            right: -40px;
        }
    }
}