.highlights{
    .px-4{
        @media (max-width: 991px) {
            &:first-child,&:nth-child(2){
                margin-bottom: 3rem;
            }
        }
    }
    h2{
        display: inline-block;
    }
    p{
        font-size: 12px;
        text-align: left;
        padding: 0;
        @media (min-width: 576px) {
            font-size: 14px;
        }
        @media (min-width: 768px) {
            padding: 0 10px;
        }
    }
    button{
        transform: translate(5px,-5px);
        @media (min-width: 375px) {
            transform: translate(5px,-20px);
        }
        @media (min-width: 768px) {
            transform: translate(5px,-15px);
        }
        @media ( min-width: 1200px ) {
            transform: translate(5px, -25px);
        }
        &:hover{
            transform: translate(-10px,-20px) scale(1.05);
            @media (min-width: 768px) {
                transform: translate(5px,-15px) scale(1.05);
            }
            @media ( min-width: 1200px ) {
                transform: translate(5px, -25px) scale(1.05);
            }
        }
    }
    .icon-video{
        position: relative;
        video{
           
            border-radius: 50%;
            width: calc(100% - 15px);
            margin: 7.5px; 
            margin-bottom: 0;
            @media(min-width: 576px){
                width: calc(100% - 15px);
                margin: 10.5px; 
                margin-bottom: 7.5px;
            }
        }
        &::after{
            content: " ";
            position: absolute;
            width: 100%;
            left: 0;
            top: 0;
            height: 100%;
            border: 7.5px solid white;
            border-radius: 50%;
            box-shadow: 5px 5px 11px #6565654a,
                        0px 2px 2px #FFFFFF84,
                        -5px -5px 11px #FFFFFF84;
            @media(min-width:576px){
                border: 12.5px solid white;
            }
        }
    }
    img{
        box-shadow: 5px 5px 11px #6565654a,
                    0px 2px 2px #FFFFFF84,
                    -5px -5px 11px #FFFFFF84;
    }
}