div.modalBackdrop{
    z-index: 150;
}
.modal{
    display: block;
    z-index: 151;
    position: fixed;
    max-height: 100vh;
    width: 100vw;
    height: auto;
    max-width: 1140px;
    background: white;
    padding: 10px 40px;
    white-space: break-spaces;
    overflow: auto;
    top: 0;
    left: 0;
    ul{
        list-style: none;
        padding-left: 20px;
    }
    @media ( min-width: 768px ) {
        padding: 40px 80px;
        width: 80vw;
        max-height: 80vh;
        top: 10vh;
        margin-left: 50%;
        transform: translateX(-50%);
    }
    > .button{
        position: absolute;
        right: 5px;
        top: 15px;
        width: 35px;
        height: 35px;
        font-size: 18px;
        line-height: 2;
    };
}