label.checkbox{
    display: flex;
    flex-direction: row;
    align-items: center;
    .checkmark{
        width: 45px;
        height: 45px;
        background: transparent linear-gradient(136deg, #FFFFFF 0%, #E7E8EB 100%) 0% 0% no-repeat padding-box;
        border-radius: 50%;
        transform: none !important;
        margin-right: 15px;
        cursor: pointer;
        color: #BFBFBF;
        transition: all 0.3s ease-out;
        flex-shrink: 0;

        &:hover{
            opacity: .97;
        }
        svg{
            width: 100% !important;
            height: 50%;
            position: relative !important;
            top: 50%;
            transform: translate(2%,-50%);
        }
        &.checked {
            color: $orange;
        }
    }
    input:checked ~ .checkmark{
        color: $orange;
    }
    a{
        color: $white;
        &:hover,&:focus{
            text-decoration: none;
            font-weight: 600;
        }
        @media (max-width: 991px ) {
            width: calc( 100% - 45px );
        }   
    }
}

#jobs-form{
    a{
        color: black;
    }
}