.blue-bg {
    background-color: $blue;
}

.green {
    color: $green;
}

.dark-green {
    color: $dark-green;
}

.green-bg {
    background: $green;
}

.light-blue {
    color: $light-blue;
}

.light-blue-bg {
    background: $light-blue;
}

.light-green {
    color: $light-green;
}

.light-green-bg {
    background: $light-green;
}

.white {
    color: $white;
}

.orange {
    color: $orange;
}

.black {
    color: $black;
}

.light-orange {
    color: $light-orange;
}

.light-orange-bg {
    background-color: $light-orange;
}

.red {
    color: $red;
}

.orange-bg {
    background-color: $orange;
}

.orange {
    color: $orange;
}

.light-yellow-bg {
    background-color: $light-yellow;
}

.yellow-bg {
    background-color: $yellow;
}

.black-bg {
    background-color: $black;
}

.white-bg {
    background-color: $white;
}

.silver-bg {
    background-color: $silver;
}

.light-silver-bg {
    background-color: $light-silver;
}

.dark-silver {
    color: $dark-silver;
}

.dark-silver-bg {
    background-color: $dark-silver;
}