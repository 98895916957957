#jobs-banner{
    .sliver-mountain::before{
        background-color: $light-blue !important;
    }
}

#jobs-section2{
    .title-triangle{
        margin-left: 50px;
    }
    p{
        white-space: break-spaces;
    }
}

#jobs-section3{
    filter: drop-shadow( 0px -10px 20px #0000002C);
    position: relative;
    .title-triangle{
        margin-left: 50px;
    }
    h2,.title-triangle{
        position: relative;
        z-index: 3;
    }
    &::before{
        content: " ";
        width: 100%;
        height: 50px;
        position: absolute;
        -webkit-clip-path: polygon(15% 0, 100% 100%, 100% 100%, 0% 100%);
        clip-path: polygon(15% 0, 100% 100%, 100% 100%, 0% 100%);
        background: $light-yellow;
        z-index: 1;
        top: 1px;
        left: 0;
    }
    &::after{
        content: " ";
        width: 100%;
        height: 105%;
        position: absolute;
        background: $light-yellow;
        z-index: 0;
        top: 50px;
        left: 0;
    }
    .img-dashed{
        position: absolute;
        width: 30vw;
        z-index: 2;
        right: 0;
        bottom: 40px;
    }
}
#section-jobs{
    padding-bottom: 100px;
    padding-top: 50px;
    position: relative;
    @media(min-width:992px){
        padding: 100px 0;
    }
    &::before{ 
        background: $blue;
        -webkit-clip-path: polygon(0% 5%, 100% 0%, 100% 100%, 0% 95%);
        clip-path: polygon(0% 5%, 100% 0%, 100% 100%, 0% 95%);
        content: " ";
        width: 100%;
        top: -15px;
        height: calc( 100% + 50px);
        left: 0;
        z-index: 1;
        position: absolute;
        @media (min-width: 768px) {
            -webkit-clip-path: polygon(1% 7%, 100% 0%, 100% 100%, 3% 96%);
                clip-path: polygon(1% 7%, 100% 0%, 100% 100%, 3% 96%);
                top: -50px;

        }
        @media (min-width: 1299px) {
            padding: 130px 0;
            -webkit-clip-path: polygon(3% 14%, 100% 0%, 100% 100%, 7% 92%);
                clip-path: polygon(3% 14%, 100% 0%, 100% 100%, 7% 92%);
        }
    }
    .container{
        position: relative;
        z-index: 5;
    }
    .ml3{
        margin-left: 3px;
        margin-right: -3px;
    }
    .light-yellow-bg{
        position: absolute;
        width: 100%;
        height: 200px;
        top: 0;
        z-index: 0;
    }
    &.modalOpen{
        z-index: 150;
    }
    h2{
        display: inline-block;
        .title-triangle{
            &.top-md{
                margin-left: 15px;
            }
            &.down-sm{
                margin-left: auto;
                margin-right: 65px;
            }
        }
    }
    .list-jobs{
        height: fit-content;
        .Backdrop{
            top: 0;
        } 
        // @media (min-width: 375px) and (max-width: 767px) {
        //     height: 316px;
        // }
        @media ( min-width: 1200px ) {
            height: 356px;
        }
        padding-left: 3px;

        .jobItem{
            display: flex;
            flex-direction: row;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            margin: 30px 0;
            padding: 15px 30px;
            box-shadow: inset -6px -5px 12px #007b7bb8, 1px 1px 7px #007b7bb8;
            border-radius: 16px;
            position: relative;
            &:first-child{
                margin-top: 3px;
            }
            &:last-child{
                margin-bottom: 3px;
            }
            &.visited{
                button{
                    color: $orange;
                }
            }
            p{
                padding: 0;
                margin: 0;
                color: $black;
                white-space: nowrap;
                overflow: hidden;
                @include font-size(15);
                @media (min-width: 768px) {
                    @include font-size(17);
                    margin-right: 20px;
                }
                @media (min-width: 1200px) {
                    @include font-size(19);
                }
            }
            @media (max-width: 767px) {
                .button{
                    opacity: 0;
                    width: 100%;
                    position: absolute;
                    left: 0;
                    height: 100%;
                }    
                &.visited{
                    p{
                        opacity: .6;
                    }
                }
            }
        }
    }
    .search{
        margin-top: 15px;
        @media ( min-width: 768px ) {
            margin-top: 30px;
        }
        padding-right: 46px;
        .formField {
            padding: 0 !important;
            label{
                padding: 0 30px;
                margin-left: 0 !important;
            }
            input{
                padding: 18px 30px;
                margin-bottom: 0;
            }
        }

    } 
    .rcs-custom-scroll{
        &::before {
            box-shadow: inset -4px 0px 4px #007b7bb8;
        }
        .rcs-inner-container{
            @media ( max-width: 1199px ) {
                max-height: 70vh;
            }
        }
    }
}

#jobs-section5{
    position: relative;
    &::after{
        content: " ";
        position: absolute;
        bottom: -150px;
        left: 0;
        width: 100%;
        height: 150px;
        background: $black;
    }
    .grey-degrade{
        box-shadow: 5px 5px 11px #65656525;
    }
}

#jobs-form{
    position: relative;
    z-index: 2;
    filter: drop-shadow( 0px -10px 20px #0000002C);
    background-color: $light-yellow;
    &:after{
        content: " ";
        position:absolute;
        width: 100%;
        bottom: -199px;
        height: 200px;
        background-color: $light-yellow;
    }
    &:before{
        clip-path: polygon(25% 81%, 0 100%, 100% 100%);
        background-color: $light-yellow;
        width: 100%;
        height: 200px;
        top: -199px;
        content: " ";
        position:absolute;
        
        // @media (max-width: 1299px) {
        //     clip-path: polygon(25% 81%, 0 100%, 100% 100%);
        //     top: -7%;
        // }
        // @media (max-width: 1299px) {
        //     top: -6%;
        // }
        // @media (max-width: 767px) {
        //     clip-path: polygon(25% 0, 100% 4%, 100% 100%, 0 100%, 0 4%);
        //     top: -4%;
        // }
    }
    &.openModal{
        filter: unset;
        z-index: 10;
    }
    h2{
        position: relative;
        color: black;
    }
    label{
        color: black;
    }
    .errorMsg{
        margin-top: -25px;
        margin-left: 18px;
        @include font-size(12);
        font-weight: 600;
    }
    #btnCv{
        position: relative;
        &.loading::after{
            content: " ";
            border: 8px solid $light-yellow;
            position: absolute;
            border-top: 8px solid $black;
            border-radius: 50%;
            width: 40px;
            right: -60px !important;
            left: initial !important;
            top: 0;
            height: 40px;
            animation: load2 2s infinite ease;
        }
        &.error{
            border: 2px solid $red;
        }
    }
    #btnSubmit{
        @media ( max-width: 991px) {
            margin-left: 50%;
            transform: translateX(-50%);
        }
    }   
    small{
        cursor: pointer;
    }
}



#jobs-section7{
    position: relative;
    z-index: 2;
    filter: drop-shadow( 0px -10px 20px #0000002C);
    background-color: $green;
    .title-triangle{
        position: relative;
    }
    h2{
        position: relative;
    }
    &:before{
        filter: drop-shadow( 0px -10px 20px #0000002C);
        clip-path: polygon(20% 81%, 0 100%, 100% 100%);
        //clip-path: polygon(20% 0, 100% 15%, 100% 100%, 0 100%, 0 15%);
        background-color: $green;
        width: 100%;
        height: 200px;
        top: -199px;
        content: " ";
        position:absolute;
        // @media (max-width: 1299px) {
        //     clip-path: polygon(50% 0, 100% 8%, 100% 100%, 0 100%, 0 8%);
        // }
    }
    &:after{
        content: " ";
        position:absolute;
        width: 100%;
        bottom: -199px;
        height: 200px;
        background-color: $green;
    }
}
#jobs-section8{
    position: relative;
    z-index: 2;
    filter: drop-shadow( 0px -20px 30px #00484841);
    margin-top: 8%;
    padding-bottom: 35px;
    background-color: $yellow;
    h2,h3,p,a{
        position: relative;
    }
    iframe{
        margin-top: unset;
        z-index: 3;
    }
    &:before{
        clip-path: polygon(20% 80%, 0 100%, 100% 100%);
        background-color: $yellow;
        width: 100%;
        height: 200px;
        top: -199px;
        content: " ";
        position:absolute;
        left: 0;
        @media (min-width: 576px) {
            height: 350px;
            top: -349px;
        }
        // @media (max-width: 1299px) {
        //     clip-path: polygon(20% 0%, 100% 8%, 100% 100%, 0 100%, 0 8%);
        //     top: -12%;
        // }
        // @media (max-width: 991px) {
        //     clip-path: polygon(20% 0%, 100% 6%, 100% 100%, 0 100%, 0 6%);
        //     top: -8%;
        // }
        // @media (max-width: 767px) {
        //     clip-path: polygon(20% 0%, 100% 4%, 100% 100%, 0 100%, 0 4%);
        //     top: -6%;
        // }
        // @media (min-width: 2000px) {
        //     top: 30%;
        // }
    }
    &:after{
        content: " ";
        position:absolute;
        width: 100%;
        bottom: -99px;
        height: 100px;
        background-color: $yellow;
    }
    .content{
        padding-top: 60px;
        padding-right: 120px;
        @media (min-width: 992px) {
            padding-right: 175px;
        }
        &::before{
            clip-path: polygon(15% 0, 100% 12%, 83% 70%, 0% 100%);
            background-color: $light-blue;
            width: 100%;
            height: 105%;
            left: -15%;
            top: 5px;
            content: " ";
            position: absolute;
            @media (max-width: 991px) {
                clip-path: polygon(20% 0, 100% 34%, 94% 84%, 10% 100%, 3% 0);
                top: -12%;
                left: 15px;
                width: calc( 100% - 30px );
            }
            @media (max-width: 767px) {
                top: -10%;
                left: -8%;
                width: calc( 100% + 15% );
            }
        }
        @media (max-width: 991px) {
            padding-left: 15%;
            padding-top: 30px;
            padding-bottom: 30px;
        }
        @media (max-width: 767px) {
            padding-left: 8%;
            padding-right: 5%;
        }
        .button{
            position: relative;
            a{
                color: $green;
            }
        }
    }
}
#jobs-section-9{
    position: relative;
    filter: drop-shadow( 0px -10px 20px #0000005F);
    z-index: 3;
    &::before{
        content: " ";
        width: 100%;
        height: 50px;
        position: absolute;
        -webkit-clip-path: polygon(15% 0, 100% 100%, 100% 100%, 0% 100%);
        clip-path: polygon(85% 0, 100% 100%, 100% 100%, 0% 100%);
        background: $blue;
        top: -49px;
        left: 0;
        z-index: 3;
        @media (max-width: 1299px) {
            clip-path: polygon(51% 0, 100% 75%, 100% 100%, 0 100%, 0 75%);
        }
    }
    &::after{
        content: " ";
        width: 100%;
        height: 150%;
        position: absolute;
        background: $blue;
        z-index: 0;
        top: 0;
        left: 0;
    }
    div,h2{
        position: relative;
        z-index: 3;
    }
    .title-triangle{
        margin-left: 100px;
    }
    button{
        outline: none;
        border: none;
        background-color: transparent;
        //width: auto;
    }
    .collapsing{
        margin-bottom: 1.5rem !important;
    }
    .collapse,.collapsing{
        margin-left: calc(53px + 2.5rem) !important;
        margin-top: -1.5rem !important;
    }
    .AccordionItemUI{
        .iconTitleItem {
            h3{
                transition: color .3s ease-out;
                text-align: left;
            }
        }
        .active{
            .iconTitleItem {
                h3{
                    color: $orange;
                }
            }
        }
        .iconTitleItem{
            .icon{
                filter: drop-shadow( -3px -3px 5px #FFFFFF84) drop-shadow(3px 3px 5px #6565654A);
                //max-height: 90px;
                max-width: 53px;
                min-width: 53px;
                max-height: 56px;
                min-height: 56px;
                background: unset;
                box-shadow: unset;
                &::before{    
                    clip-path: none;
                    background: transparent linear-gradient(136deg, #FFFFFF 0%, #E7E8EB 100%) 0% 0% no-repeat padding-box;
                    border: 0.2px solid transparent;
                    width: 100%;
                    height: 100%;
                    content: " ";
                    top: 0;
                    left: 0;
                    position: absolute;
                }
                padding: 1rem;
                img{
                    padding: 0 !important; 
                    position: relative;
                }
            }
        }
        &::after{
            content: none;
        }
    }
}
.modalJob{
    position: fixed;
    max-height: 100vh;
    width: 100vw;
    max-width: 1140px;
    background: white;
    padding: 10px 40px;
    z-index: 150;
    overflow: auto;
    top: 0;
    left: 0;
    @media ( min-width: 768px ) {
        padding: 40px 80px;
        width: 80vw;
        max-height: 80vh;
        top: 10vh;
        margin-left: 50%;
        transform: translateX(-50%);
    }
    > .button{
        position: absolute;
        right: 5px;
        top: 5px;
        width: 35px;
        height: 35px;
        font-size: 18px;
        line-height: 2;
    }
    .title-triangle{
        margin-left: 40px;
    }
    .p{
        margin-top: 15px;
        white-space: break-spaces;
        //line-height: 2;
        display: flex;
        flex-direction: column;
        b{
            display: contents;
        }
        a{
            color: $green;
        }
    }
    .hide_copy{
        position: absolute;
        width: 0;
        height: 0;
        opacity: 0;
        margin: 0;
        padding: 0;
    }
    .modalFooter{
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        padding-top: 40px;
        margin-top: 20px;
        &::before{
            position: absolute;
            content: " ";
            width: calc(100% + 160px);
            top: 0;
            left: -80px;
            height: calc(100% + 40px);
            background: $green;
            clip-path: polygon(0 0, 100% 20%, 100% 101%, 0% 101%);
        }
        .button{
            position: relative;
            margin-top: 0;
        }
        @media ( max-width: 767px ) {
            flex-direction: column;
            .icons{
                padding-top: 1rem;
            }
        }
    }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  