.rcs-custom-scroll {
	min-height: 0;
	min-width: 0;
    position: relative;
	.rcs-outer-container {
		overflow: hidden;
		height: 100%;
		.rcs-positioning {
			position: absolute;
			height: 100%;
            width: 30px;
			right: 0;
		}
        .rcs-custom-scrollbar {
            opacity: 1;
        }
		
	}
	.rcs-inner-container {
		overflow-x: hidden;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
        padding-right: 46px;
		&:after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			height: 0;
			background-image: linear-gradient(to bottom, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.05) 60%, rgba(0,0,0,0) 100%);
			pointer-events: none;
			transition: height 0.1s ease-in;
			will-change: height;
		}
		& > div{
			padding-left: 5px;
		}
	}
	.rcs-inner-container.rcs-content-scrolled {
		&:after {
			height: 5px;
			transition: height 0.15s ease-out;
		}
	}
	.rcs-custom-scrollbar {
		position: absolute;
		height: 100%;
		width: 26px;
		right: 0;
		z-index: 0;
		transition: opacity 0.4s ease-out;
		padding: 6px 0;
		box-sizing: border-box;
		will-change: opacity;
		pointer-events: none;
	}
	.rcs-custom-scrollbar.rcs-custom-scrollbar-rtl {
		right: auto;
		left: 3px;
	}
	.rcs-custom-scroll-handle {
		position: absolute;
		width: 100%;
		top: 0;
	}
	.rcs-inner-handle {
		height: calc(100% - 12px);
		width: 18px;
		//height: 30px;
		margin-top: 6px;
		background: transparent linear-gradient(330deg, #F2F3F6 0%, #E5E6EC 100%) 0% 0% no-repeat padding-box;
		box-shadow: -1px -1px 8px #007B7B;
		border-radius: 20px;
		position: relative;
		&::before,&::after{
			content: " ";
			position: absolute;
			width: 60%;
			left: 20%;	
			filter: blur(1px)		
		}

		&::before{
			top: 25%;
			height: 25%;
			border-top: 1px solid #586c8a80;
			border-bottom: 1px solid #586c8a80;	
		}
		&::after{
			bottom: 25%;
			height: 25%;
			border-bottom: 1px solid #586c8a80;
		}
	}
}
.rcs-custom-scroll{
    &::before {
		content: " ";
		height: calc(100% - 20px);
        top: 10px;
		position: absolute;
		width: 8px;
		right: 14px;
		box-shadow: inset -4px 0px 4px #586C8A80;
		border-radius: 16px;
	}
}