#consulting-section3{
    .buttonOvalImage {
        .OvalImage {
            .backgroundImg{
                @media (min-width: 992px) and (max-width:1199px){
                    width: 86%;
                    transform: translate(7%, 5%) scale(1.2);
                    max-width: 830px;
                }
                @media (min-width: 1200px){
                    max-width: 1100px;
                    transform: translate(1%, -2%) scale(1.1);
                }
            }
            @media(min-width: 992px) and (max-width:1199px){
                .grey-degrade{
                    max-width: 300px;
                    margin-left: 40px;
                }
                img{
                    margin-left: 50px;
                    margin-top: 50px; 
                }
            }
            @media(min-width: 1200px){
                .grey-degrade{
                    margin-left: 60px;
                }
                img{
                    margin-left: 50px;
                    margin-top: 50px; 
                }
            }
        }
    }
}