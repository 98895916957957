.shadow-blog{
    box-shadow: 20px 40px 50px #00000031;
}

.slick-dots li button:before{
    font-size: 16px;
    line-height: 16px;
}

#blog{
    .title-triangle{
        &.down-md{
            left: 43%;
            position: absolute;
        }
    }
}