.AccordionUI{
    &.bg{
        background-color: $light-silver;
        box-shadow: inset -3px -3px 10px rgba(0, 0, 0, 0.4), -3px -3px 10px #fff;
        border-radius: 15px;
    }
    .AccordionItemUI{
        position: relative;
        .arrow{
            color: $black;
        }
        a{
            color: $black;
            font-weight: 400;
            color: $orange;
            text-decoration: underline;
            &:hover{
                font-weight: 500;
            }
        }
        &.active{
            .arrow{
                color: $orange;
            }
            .click{
                color: $orange;
            }
        }
        .active{
            .arrow{
                transform: rotate(180deg);
                color: $orange;
            }
            .click{
                color: $orange;
            }
        }
        .click{
            &:hover{
                color: $orange;
                .arrow{
                    color: $orange;
                }
            }
            .button{
                padding: 0;
            }
        }
        .arrow,h3{
            transition: all .3s ease-out;
        }

        &:not(:last-child)::after{
            background: #EAEBF3 0% 0% no-repeat padding-box;
            box-shadow: -1px -1px 2px #ffffffe6;
            content: " ";
            width: 100%;
            left: 0;
            bottom: 0;
            height: 2px;
            position: absolute;
        }
        .iconTitleItem{
            cursor: pointer;
        }
    }
    
}