#rgpc-section2,
#rgpc-section3,
#rgpc-section4,
#rgpc-section5,
#rgpc-section6,
#rgpc-section7,
#rgpc-section8 {
    position: relative;

    .container {
        position: relative;
        z-index: 1;
    }

    .arrow-container {
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
        background: transparent linear-gradient(109deg, #FFFFFF 0%, #E7E8EB 100%) 0% 0% no-repeat padding-box;
        border: 0.2px solid transparent;
        box-shadow: 5px 5px 11px rgba(101, 101, 101, 0.2901960784);
        border-radius: 50px;
        margin-right: 15px;

        .arrow {
            width: 18px;
            height: 18px;

            svg {
                width: 100%;
                height: 100%;
            }
        }
    }

    .break-line {
        background: #EAEBF3 0% 0% no-repeat padding-box;
        box-shadow: -1px -1px 2px rgba(255, 255, 255, 0.9019607843);
        width: 100%;
        height: 2px;
        margin: 20px 0;
    }

    .icon-container {
        min-width: 110px;
        width: 110px;
        margin-right: 30px;

        .diamond-top-icon {
            .icon {
                width: 100%;
                min-width: unset;
                max-width: unset;
            }
        }
    }

    .white-shape {
        margin-top: 20px;

        @media (min-width:768px) {
            margin-top: 50px;
        }

        &::before {
            content: "";
            background-color: $white;
            width: 100vw;
            height: calc(100% + 150px);
            position: absolute;
            z-index: 0;
            top: -30px;
            left: 0;
            clip-path: polygon(0% 0, 100% 5%, 100% 100%, 0 100%, 0 0%);

            @media (min-width:768px) {
                clip-path: polygon(0% 0, 100% 10%, 100% 100%, 0 100%, 0 0%);
            }

            @media (min-width:1024px) {
                clip-path: polygon(0% 0, 100% 15%, 100% 100%, 0 100%, 0 0%);
            }
        }
    }

    .black-shape {
        margin-top: 20px;

        @media (min-width:768px) {
            margin-top: 50px;
        }

        &::before {
            content: "";
            background-color: $black;
            width: 100vw;
            height: calc(100% + 150px);
            position: absolute;
            z-index: 0;
            top: -30px;
            left: 0;
            clip-path: polygon(15% 2%, 100% 5%, 100% 100%, 0% 100%, 0 5%);

            @media (min-width:1024px) {
                clip-path: polygon(15% 0%, 100% 10%, 100% 100%, 0% 100%, 0 10%);
            }

            @media (min-width:1440px) {
                clip-path: polygon(15% 0%, 100% 15%, 100% 100%, 0% 100%, 0 15%);
            }
        }
    }

    .blue-shape {
        margin-top: 20px;

        @media (min-width:768px) {
            margin-top: 50px;
        }

        &::before {
            content: "";
            background-color: $blue;
            width: 100vw;
            height: calc(100% + 40px);
            position: absolute;
            z-index: 0;
            top: -30px;
            left: 0;
            clip-path: polygon(0% 5%, 100% 0%, 100% 100%, 0% 100%, 0 0%);

            @media (min-width:768px) {
                clip-path: polygon(0% 8%, 100% 0%, 100% 100%, 0% 100%, 0 0%);
            }

            @media (min-width:1024px) {
                clip-path: polygon(0% 10%, 100% 0%, 100% 100%, 0% 100%, 0 0%);
            }
        }
    }

    a {
        text-decoration: none;
        color: unset;
        font-weight: 600;

        &:hover {
            color: $orange;
        }
    }
}

#rgpc-section4 {
    .title-triangle {
        border-top-color: $light-yellow ;
    }

    .arrow-container {
        background: $black;
        box-shadow: unset;

        .arrow {
            color: $white;
            width: 24px;
            height: 24px;
        }
    }

    h2,
    h3 {
        color: $light-yellow;
    }

    p {
        color: $white;
    }

    .break-line {
        background: $black 0% 0% no-repeat padding-box;
        box-shadow: -1px 1px 2px rgba(255, 255, 255, 0.9019607843);
    }
}

#rgpc-section6 {
    margin-bottom: 0;
}

#rgpc-section7 {
    margin-top: 0;
    padding-top: calc(3rem + 20px) !important;
    margin-bottom: 0;

    @media (min-width: 768px) {
        padding-top: calc(3rem + 25px) !important;
    }

    @media (min-width: 992px) {
        padding-top: calc(3rem + 30px) !important;
    }

    h4 {
        color: $green;
    }
}

#rgpc-section8 {
    margin-top: 0;
}

.custom-end {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        background-color: $white;
        width: 100%;
        height: 100vh;
        top: -60px;
        left: 0;
    }
}