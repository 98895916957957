.grey-degrade{
    background: transparent linear-gradient(106deg, #FFFFFF 0%, #E7E8EB 100%) 0% 0% no-repeat padding-box;
    box-shadow:  5px 5px 11px #d5d5d5, 
             -5px -5px 11px #ffffffb0;
    border-radius: 8px;
    padding: 15px;
    @media(min-width: 576px){
        padding: 18px;
    }
    @media(min-width: 768px){
        padding: 23px;
    }
    &.click{
        max-width: 350px;
        cursor: pointer;
        h3{
            line-height: 1.2;
            margin: 0;
            text-transform: uppercase;
            @media (min-width: 768px) {
                &.active{
                    color: $orange;
                }
            }
        }
    }
}