.dashedSection{
    .itemDashed {
        @media ( max-width: 991px  ) {
            padding-left: 5%;
        }
        &.pl{
            &:not(.last):after{
                content: " ";
                width: 60px;
                height: 1.5px;
                bottom: -30px;
                left: 165px;                 
                //transform: rotate(90deg);
                position: absolute;
                background-image: linear-gradient(90deg, #000, #000 50%, transparent 60%, transparent 100%);
                background-size: 16px 1.5px;
                transform: rotate(57deg) translateX(-50%);
                @media ( min-width: 992px  ) {
                    left: 9%;
                    padding-left: 5%;
                }
            }
        }
        &.pr{
            @media ( min-width: 992px  ) {
                padding-right: 5%;
            }
            &:not(.last)::after{
                content: " ";
                width: 63px;
                height: 1.5px;
                bottom: 30px;
                left: 165px;                    
                //@extend: rotate(90deg);
                position: absolute;
                background-image: linear-gradient(90deg, #000, #000 50%, transparent 60%, transparent 100%);
                background-size: 16px 1.5px;
                transform: rotate(-57deg) translateX(-50%);
                @media ( min-width: 992px  ) {
                    left: unset;
                }
            }
        }
        .number{
            @include font-size(45);
            @media ( max-width: 991px  ){
                margin-left: 30px;
            }
        }
        // .img{
        //     position: relative;
        //     height: 100%;
        //     .backgroundImg{
        //         position: absolute;
        //         height: 100%;
        //         max-height: unset;
        //         left: 0%;
        //         top: 0;
        //         transform: translate(-5%,-2%) scale(1.4);
        //         z-index: 0;
        //     }
        // }
        @media (max-width: 575px ) {
            padding-left: 8%;
        }
    }
}