#menuModal {
    .buttons {
        position: fixed;
        z-index: 102;
        right: -100vw;
        top: 0;
        transition: all 0.3s linear;

        .button {
            padding: 5px 12px !important;
        }

        &.show {
            top: 5%;
            right: 5%;
            transition: all 0.3s 0.2s linear;
        }
    }

    .menu-modal {
        margin-right: 10px;

        .button {
            position: relative;
            z-index: 102;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px !important;
            background: transparent linear-gradient(109deg, #FFFFFF 0%, #E7E8EB 100%) 0% 0% no-repeat padding-box;
            border: 0.2px solid transparent;
            box-shadow: 5px 5px 11px rgba(101, 101, 101, 0.2901960784);
            border-radius: 50px;
            margin: 0 15px;
            transition: all 0.3s ease-out;

            &.active {
                -webkit-transform: scale(1.05);
                -ms-transform: scale(1.05);
                transform: scale(1.05);
                color: #dd5100;
                background: rgba(0, 0, 0, 0) linear-gradient(-109deg, #fff, #e7e8eb) 0 0 no-repeat padding-box;
                border-color: #fff;
            }

            svg {
                position: absolute;
            }
        }

        .locale-container {
            position: relative;

            .locale-menu {
                position: absolute;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                width: calc(100% + 14px);
                height: 0;
                opacity: 0;
                top: -7px;
                left: -7px;
                background: #F7F7F8A3 0% 0% no-repeat padding-box;
                overflow: hidden;
                border-radius: 33px;
                border: 1px solid #FFFFFF;
                transition: all 0.3s linear;
                padding: 5px 0;
                z-index: 101;

                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    transition: all 0.3s ease-in;
                    transition: all 0.1s ease-out;
                    opacity: 0;
                    top: 0;

                    li {
                        width: 100%;
                        padding: 0;

                        button {
                            background: transparent;
                            border: 0;
                            font-weight: 700;
                            width: 100%;
                            padding: 0;
                            text-align: center;
                            margin: 7px 0;

                            &.active,
                            &:focus,
                            &:hover {
                                color: $orange;
                            }

                            @media(min-width:768px) {
                                margin: 5px 0;
                            }
                        }
                    }
                }

                &.show {
                    height: 150px;

                    @media(min-width: 1200px) {
                        height: 165px;
                    }

                    ul {
                        transition-delay: 0.3s;
                        opacity: 1;
                    }

                    opacity: 1;

                    >button {
                        transform: scale(1.05);
                        color: $orange;
                        background: transparent linear-gradient(-109deg, #FFFFFF 0%, #E7E8EB 100%) 0% 0% no-repeat padding-box;
                        border-color: #FFFFFF;
                    }

                    .iconG,
                    .iconM {
                        fill: $orange;
                    }
                }
            }
        }
    }

    .content {
        position: fixed;
        top: 0;
        z-index: 101;
        transition: left 0.3s linear;
        //white-space: nowrap;
        left: 150vw;

        &.show {
            left: 5vw;

            @media (min-width:768px) {
                left: 20vw;
            }

            @media (min-width:992px) {
                left: 55vw;
            }

            @media (min-width:1400px) {
                left: 65vw;
            }

            transition: left 0.3s 0.2s linear;
        }

        &::before {
            content: " ";
            position: absolute;
            width: 100vw;
            left: -5vw;
            top: 0;
            height: 125%;
            z-index: 102;
            background: #EBECEE 0% 0% no-repeat padding-box;
            clip-path: polygon(0 0, 100% 0, 100% 75%, 0% 100%)
        }

        .black-bg {
            position: absolute;
            width: 100vw;
            left: -5vw;
            top: -65px;
            height: 150%;
            z-index: 101;
            clip-path: polygon(100% 0, 100% 88%, 78% 68%, 51% 72%, 25% 50%, 0% 0%);

            @media (min-width:768px) {
                left: -15vw;
            }

            @media (min-width:992px) {
                width: 75vw;
            }

            @media (min-width: 1400px) {
                width: 50vw;
            }
        }

        .green-bg {
            position: absolute;
            width: 100vw;
            left: -5vw;
            top: -65px;
            height: 150%;
            z-index: 100;
            clip-path: polygon(100% 0%, 100% 100%, 0 72%, 33% -48%);

            @media (min-width:768px) {
                left: -15vw;
            }

            @media (min-width:992px) {
                width: 75vw;
            }

            @media (min-width: 1400px) {
                width: 50vw;
            }
        }

        ul {
            position: relative;
            list-style: none;
            margin: 0;
            margin-left: 5px;
            padding: 0;
            top: 72px;
            z-index: 103;
            padding: 30px 0;

            li {
                padding: 5px 0;

                a {
                    color: $black;
                    padding: 5px 15px;
                    margin-left: -15px;
                    transition: all 0.3s ease-out, font-size 0s;
                    text-decoration: none;

                    .arrow {
                        transition: all .3s ease-out;
                    }

                    &:hover,
                    &:focus,
                    &.active {
                        color: $orange;
                        text-decoration: none;

                        .arrow {
                            color: $orange;
                        }
                    }
                }

                &.icons {
                    a {
                        font-size: 18px;
                        line-height: 1;
                        border: unset;
                        display: contents;
                        padding: 0;

                        .button {
                            color: $green;
                        }

                        &.active,
                        &:focus,
                        &:hover {
                            .button {
                                background: unset;
                            }
                        }
                    }
                }

            }

            h3 {
                text-transform: uppercase;
                color: $black;

                &:hover {
                    color: $orange;
                }
            }

            .active {
                h3 {
                    color: $orange;
                }
            }
        }

        .AccordionUI {
            .collapsing {
                padding-left: 45px;
            }

            .collapse {
                margin-top: -1rem;
                padding-left: 45px;
            }
        }

        @media (min-width: 992px) and (max-width: 1049px) {
            margin-left: -5%;
        }
    }
}