img {
    max-width: 100%;
}

.smaller-certification {
    @media(max-width: 575px) {
        min-width: 35px;
        max-width: 45px;
    }
}

.inovadora {
    min-width: 100px;
    margin-right: 5px;

    @media(max-width: 575px) {
        max-width: 125px;
    }

    @media(min-width:1200px) {
        margin-right: 10px;

        img {
            min-width: 148px;
        }

    }
}