#attendenceManagement-section2{
    .title-triangle{
        margin-left: 35px;
    }
    .buttonOvalImage {
        .OvalImage {
            .backgroundImg{
                @media (min-width: 992px) and (max-width:1199px){
                    width: 94%;
                    transform: translate(2%, 3%) scale(1.1);
                }
                @media (min-width: 1200px){
                    max-width: 1100px;
                    transform: translate(1%, -1%) scale(1.1);
                }
            }
            @media(min-width: 992px) and (max-width:1199px){
                .grey-degrade{
                    max-width: 300px;
                    margin-left: 40px;
                }
                img{
                    margin-left: 50px;
                    margin-top: 50px; 
                }
            }
            @media(min-width: 1200px){
                .grey-degrade{
                    margin-left: 50px;
                }
                img{
                    margin-left: 50px;
                    margin-top: 60px; 
                }
            }
        }
    }
}