//ROTATE TRANSPARENT BG
.rotate-center {
	-webkit-animation: rotate-center 14s linear infinite both;
	  animation: rotate-center 14s linear infinite both;
  transform-origin: center center;
  will-change: transform;            
}
.rotate-center-right {
	-webkit-animation: rotate-center 14s linear infinite both;
    animation: rotate-center 14s linear infinite both;
  transform-origin: 70%;   
  will-change: transform;                     
}

@-webkit-keyframes rotate-center {
    0% {
      -webkit-transform: rotate(0);
              transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes rotate-center {
    0% {
      -webkit-transform: rotate(0);
              transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }

  //para quando roda não ficar sobreposto
  svg{
    overflow: visible;
  }