.titleText{
    .carouselTitleText {
        z-index: 5;
        .slick-dots li{
            &.slick-active{
                button:before{
                    color: $black;
                }
            }
            button:before{
                color: #969696;
                opacity: 1;
            }
        }
    }
    .titleTextItem{
        position: relative;
        margin: 5px 0;
        @media ( min-width: 992px ) {
            margin: 15px 0;
        }
        h3,p{
            position: relative;
            z-index: 4;
        }
        .title{
            position: relative;
            h3{
                padding-top: 25px;
                padding-left: 30px;
                padding-right: 85px;
                color: $white;
            }
            padding: 0 15px; 
            img{
                position: absolute;
                z-index: 2;
                top: 0;
                width: calc( 100% - 40px );
                left: 1px;
                @media (max-width: 575px) {
                    max-width: 270px;
                }
            }
        }
        .text{
            padding: 15px; 
            margin-left: 40px;
            position: relative;
            p{
                padding-top: 15px;
                padding-left: 25px;
                padding-right: 40px;
                padding-bottom: 35px;
                color: $black;
            }
            &::before{
                bottom: 2px;
                content: " ";
                position: absolute;
                width: calc(100% - 40px );
                height: 100%;
                z-index: 1;
                background: $white;
                clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
            }
            &::after{
                content: " ";
                position: absolute;
                width: calc(100% - 60px );
                left: 25px;
                height: calc(100% - 25px );
                background: $blue;
                bottom: 15px;                
                z-index: 3;
                clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
            }
            @media ( max-width: 375px ) {
                margin-left: 20px;
            }
        }
    }
}