.Backdrop{
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 100;
    left: 0;
    top: -100vh;
    background-color: rgba(0, 0 ,0 , 0.64);
    transition: all 0.4s 0.1s linear;
    cursor: pointer;
    &.animation-right{
        top: 0;
        left: 100vw;
        transition: left 0.4s linear;
        &.show{
            transition: left 0.4s linear;
            left: 0;
        }
    }
    &.show{
        transition: all 0.4s linear;
        top:0;
    }
    &.hide{
        opacity: 0;
        transition: none;

    }
}