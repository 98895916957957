footer {
    display: block;
    background-color: $white;
    position: relative;
    z-index: 9;
    margin-top: calc(100px + 3rem);
    filter: drop-shadow(0px -10px 15px rgba(0, 0, 0, 0.3));

    &:before {
        content: " ";
        position: absolute;
        top: -80px;
        width: 100%;
        height: 100px;
        clip-path: polygon(31% 0, 100% 24%, 100% 100%, 0 100%, 0 24%);
        display: flex;
        align-items: flex-end;
        background-color: $white;
    }

    h3 {
        @include font-size(10);
        font-weight: 600;
    }

    h4 {
        @include font-size(12)
    }

    ul {
        list-style: none;
        padding: 0;
    }

    a {
        text-decoration: none !important;
        color: $grey;
        @include font-size(10);
        line-height: 2;

        &.green {
            &:hover {
                color: $green;
                font-weight: 600;
            }

            &.active {
                font-weight: 600;
            }
        }

        &:not(.green) {

            &.active,
            &:hover {
                color: $orange !important;
                font-weight: 600;
            }
        }
    }

    .copy {

        p,
        a {
            color: $grey !important;
            @include font-size(10);
        }

        p {
            padding: 20px 15px;

            @-moz-document url-prefix() {
                @media (max-width: 991px) {
                    span {
                        font-size: .6rem;
                    }
                }
            }
        }

        @media(min-width:768px) {
            p {
                padding: 20px 10px;
            }
        }
    }

    .iconFooter {
        .button {
            svg {
                width: 15px;
                height: 15px;
            }

            @media (min-width: 992px) and (max-width: 1199px) {
                margin: 0 8px;
            }
        }
    }

    .button {
        height: 0;
        width: 0;
        position: relative;
        z-index: 102;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        background: transparent linear-gradient(109deg, #FFFFFF 0%, #E7E8EB 100%) 0% 0% no-repeat padding-box;
        border: 0.2px solid transparent;
        box-shadow: 5px 5px 11px rgba(101, 101, 101, 0.2901960784);
        border-radius: 50px;
        margin: 0 15px 0 20px;
        transition: all 0.3s ease-out;
        color: #007B7B;

        &.active {
            -webkit-transform: scale(1.05);
            -ms-transform: scale(1.05);
            transform: scale(1.05);
            color: #dd5100;
            background: rgba(0, 0, 0, 0) linear-gradient(-109deg, #fff, #e7e8eb) 0 0 no-repeat padding-box;
            border-color: #fff;
        }

        img {
            position: absolute;
        }
    }

    .socials {
        .button {
            margin: 0 10px 0 0;

            @media (max-width:767px) {
                margin: 0;
            }
        }

        img {
            height: 30px;
            width: 30px;
        }
    }
}