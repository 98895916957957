#homepage-section-3{
    z-index: 10;
}
#homepage-section-3, .banner .content{
    overflow: hidden;
    position: relative;
    .title{
        display: flex;
        flex-direction: column;
        span{
            color: $white;
            position: relative;
            align-self: center;
            margin: 5px 0;
            transform: translateY(0) !important;
            strong{
                color: $light-orange;
                font-weight: 700;
            }
            i{
                position: absolute;
                width: 125%;
                height: 100%;
                left: 62%;
                background-color: $black;
                @media ( max-width: 575px ) {
                    transform: translateX(-59.5%);
                }
            }
            .path{ 
                position: relative;
                margin: 5px;
            }
            &.span-top {
                text-align: center;
                i{
                    transform: skewX(40deg) translateX(-59.5%);
                }
                .h3{
                    @media(max-width: 576px){
                        font-size: 14px;
                    }
                }
            }
            &.span-button {
                i{
                    transform: skewX(-40deg) translateX(-59.5%);
                }
                .h3{
                    @media(max-width: 576px){
                        font-size: 14px;
                    }
                }
            }
            @media ( min-width: 768px ) and ( max-width: 991px ) {
                i{
                    width: 110%;
                    left: 55%;
                }
                &.span-top {
                    i{
                        transform: skewX(40deg) translateX(-55%);
                    }
                }
                &.span-button {
                    i{
                        transform: skewX(-40deg) translateX(-55%);
                    }
                }
            }
        }
    }

    .title-triangle{
        position: absolute;
        top: - 30px;
        right: 65px;
    }
}

#section-jobs{
    position: relative;
    z-index: 2;
    .containter{
        position: relative;
    }
}

.isHome{
    #section-jobs ~ section{
        position: relative;
        z-index: 1;
        transform: translateY(-170px);
        @media(min-width:992px){
            transform: translateY(-245px);
        }
    }
}
#homepage-banner ~ footer{
    position: relative;
    z-index: 1;
    margin-top: -100px !important;
    @media(min-width:992px){
        margin-top: -185px !important
    }
    @media (min-width:1200px) {
        margin-top: -145px !important;
    }
}
#blog{
    z-index: 0 !important;
}