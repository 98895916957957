.cta{
    height: 200px;
    position: relative;
    display:flex;
    align-items:center;
    @media(min-width:576px){
        height: 150px;
    }
    @media(min-width: 768px){
        height: 200px;
        margin: 8rem 0;
    }
    @media(min-width: 1600px){
        height: 250px;
    }
    &.big{
        height: fit-content; 
        p{
            margin:0;
        }
        .polygon{
            
            @media(min-width:768px){
                top: -50px;
            }
            @media(min-width:1200px){
                top: -80px;
            }
            @media(min-width:1600px){
                top: -125px;
            }
        }
        .title, .content{
            @media(min-width: 576px){
                max-width: 100%;
            }
        }
    }
    p{
        line-height: 1.7;
    }
    .title, .content{
        text-align: center;
       
        @media(min-width:768px){
            text-align: left;
            max-width: 90%;
        }
        @media(min-width:1600px){
            max-width: 75%;
        }
        @media(min-width:1800px){
            max-width: 85%;
        }
    }
    .polygon{
        height: 200px;
        width: 50%;
        clip-path: polygon(24% 1%, 100% 30%, 90% 90%, 0% 100%);
        position: absolute;
        right: -50px;
        top: -150px;
        @media(min-width:768px){
            height: 300px;
            width: 135%;
            left: -50px;
            top: -100px;
        }
        @media(min-width:1600px){
            width: 150%;
            height: 350px;
            top: -125px;
        }
        button{
            position: absolute;
            top: 50%;
            left: 15%;
            @media ( min-width: 1200px ) {
                left: 30%;
            }
        }
    }
}