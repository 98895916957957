.IconTitleButton{
    h2{
        display: inline-block;
        position: relative;
        .down-md{
            margin-left: auto;
            margin-right: 65px;
        }
    }
}


.de .IconTitleButton .IconTitleButtonItem h3{
    hyphens: auto;
}